// Libraries
import {Environment, GraphQL, Platform} from '@supermove/sdk';

// App
// @ts-expect-error TS(2307): Cannot find module '../package' or its correspondi... Remove this comment to see the full error message
import packageJson from '../package';

const {version, build} = packageJson;
const AppInfo = {
  getBuildNumber: () => build,
  getVersion: () => version,
  name: Environment.getAppName(),
  platform: Platform.OS,
};

const cache = GraphQL.createCache();
// @ts-expect-error TS(2345): Argument of type '{ cache: InMemoryCache; appInfo:... Remove this comment to see the full error message
const client = GraphQL.createClient({cache, appInfo: AppInfo});

export {AppInfo, client};
