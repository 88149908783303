import {FormikConfig, FormikValues, useFormik} from 'formik';

export type FormValues = FormikValues;

/**
 * Weird hack to get the type equivalent to:
 *   type Form<Values> = ReturnType<typeof useFormik<Values>>
 * TODO(atsu): Clean this up once we upgrade TypeScript & can use this
 *   new syntax. See https://github.com/microsoft/TypeScript/pull/47607
 */
class UseFormikWrapper<Values extends FormValues> {
  useFormik(config: FormikConfig<Values>) {
    return useFormik<Values>(config); // eslint-disable-line
  }
}
export type Form<Values extends FormValues = FormValues> = ReturnType<
  UseFormikWrapper<Values>['useFormik']
>;

export type MutationError = {
  field: string;
  message: string;
};

export type MutationResponse<Results extends Record<string, any>> = {
  response: (Results | undefined) & {
    errors?: MutationError[];
  };
};
