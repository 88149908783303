import {useEffect, useRef, useState} from '@supermove/hooks';

const useTypingAnimation = (
  textToDisplay: string,
): {animatedText: string; completedTyping: boolean} => {
  const [animatedText, setAnimatedText] = useState<string>('');
  const [completedTyping, setCompletedTyping] = useState<boolean>(false);
  const currentIndexRef = useRef<number>(0);

  useEffect(() => {
    if (currentIndexRef.current >= textToDisplay.length) {
      setCompletedTyping(true);
      return;
    }

    setCompletedTyping(false);

    const intervalId = setInterval(() => {
      setAnimatedText((currentDisplay) => {
        if (currentIndexRef.current >= textToDisplay.length) {
          clearInterval(intervalId);
          setCompletedTyping(true);
          return textToDisplay;
        }

        currentIndexRef.current += 1;
        return textToDisplay.slice(0, currentIndexRef.current);
      });
    }, 20);

    return () => clearInterval(intervalId);
  }, [textToDisplay]);

  return {animatedText, completedTyping};
};

export default useTypingAnimation;
