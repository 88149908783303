/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  DateTime: {input: any; output: any};
  JSONString: {input: any; output: any};
  Date: {input: any; output: any};
  Upload: {input: any; output: any};
};

export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  resultsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectSortSetting = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export enum Status {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Editing = 'EDITING',
  InReview = 'IN_REVIEW',
  SubmittedToPe = 'SUBMITTED_TO_PE',
  Suspended = 'SUSPENDED',
}

export type AcceptProductUserInviteForm = {
  productUserId?: InputMaybe<Scalars['Int']['input']>;
};

export type ConfirmationStepForm = {
  actionText?: InputMaybe<Scalars['String']['input']>;
  confirmationStepsId?: InputMaybe<Scalars['Int']['input']>;
  documentUuid?: InputMaybe<Scalars['String']['input']>;
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  showByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  valueTwo?: InputMaybe<Scalars['String']['input']>;
};

export type JobDateForm = {
  date?: InputMaybe<Scalars['Date']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  isEstimatedRange?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ApplyJobTypeConfigsToJobTypesForm = {
  jobTypeConfigs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeIdsToUpdate?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type TagForm = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignCollectionsToContainerForm = {
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  containerId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignContainersToProjectForm = {
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignDriverAndTrucksToTripForm = {
  driverId?: InputMaybe<Scalars['Int']['input']>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
  truckIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AssignDriverToSlotForm = {
  driverId?: InputMaybe<Scalars['Int']['input']>;
  slotId?: InputMaybe<Scalars['Int']['input']>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignJobToBranchForm = {
  defaultOrganizationId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignOfficeUsersToProjectWorkflowForm = {
  projectId?: InputMaybe<Scalars['Int']['input']>;
  roundRobinUserIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  specificUserId?: InputMaybe<Scalars['Int']['input']>;
  userRoleKind?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['Int']['input']>;
  workflowProjectAssignmentType?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignProjectToBranchForm = {
  defaultOrganizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignShipmentsToTripForm = {
  shipmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignTruckToSlotForm = {
  slotId?: InputMaybe<Scalars['Int']['input']>;
  truckId?: InputMaybe<Scalars['Int']['input']>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignUsersToCrewForm = {
  crewId?: InputMaybe<Scalars['Int']['input']>;
  jobUserForms?: InputMaybe<Array<InputMaybe<JobUserForm>>>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobUserForm = {
  branchCode?: InputMaybe<Scalars['String']['input']>;
  crewId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobUserId?: InputMaybe<Scalars['Int']['input']>;
  moverPositionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  position?: InputMaybe<Scalars['String']['input']>;
  primaryMoverPositionId?: InputMaybe<Scalars['Int']['input']>;
  tipAmount?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssignUsersToCrewWithCrewSlotsForm = {
  assignUsersToCrewForm?: InputMaybe<AssignUsersToCrewForm>;
  crewSlotForms?: InputMaybe<Array<InputMaybe<CrewSlotForm>>>;
  shouldRunUpsertCostsAndBillingEngineAsync?: InputMaybe<Scalars['Boolean']['input']>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type CrewSlotForm = {
  crewId?: InputMaybe<Scalars['Int']['input']>;
  crewSlotId?: InputMaybe<Scalars['Int']['input']>;
  driverId?: InputMaybe<Scalars['Int']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  slotId?: InputMaybe<Scalars['Int']['input']>;
  truckId?: InputMaybe<Scalars['Int']['input']>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type CodatBatchExportForm = {
  codatIntegrationId?: InputMaybe<Scalars['Int']['input']>;
};

export type BeginPaymentForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  billId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BeginPaymentV3Form = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  billId?: InputMaybe<Scalars['Int']['input']>;
  billTipForm?: InputMaybe<BillTipForm>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shouldErrorOnDuplicatePayment?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BillTipForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  billId?: InputMaybe<Scalars['Int']['input']>;
  billTipId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['Int']['input']>;
};

export type BulkUpdateIntPropertyForm = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export type BulkUpdateBooleanPropertyForm = {
  booleanValue?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  property?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceForm = {
  attachmentForms?: InputMaybe<Array<InputMaybe<UpdateAttachmentForm>>>;
  billForms?: InputMaybe<Array<InputMaybe<BillForm>>>;
  billingClientForm?: InputMaybe<ClientForm>;
  customerNotes?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  documentTemplateId?: InputMaybe<Scalars['Int']['input']>;
  emailReceiptTemplateId?: InputMaybe<Scalars['Int']['input']>;
  emailTemplateId?: InputMaybe<Scalars['Int']['input']>;
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
  isAutopay?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isMainInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  paymentTerm?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  recurringTaskForm?: InputMaybe<RecurringTaskForm>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAttachmentForm = {
  attachmentId?: InputMaybe<Scalars['Int']['input']>;
  attachmentUuid?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  isVisibleToCrew?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BillForm = {
  billId?: InputMaybe<Scalars['Int']['input']>;
  billItemForms?: InputMaybe<Array<InputMaybe<BillItemForm>>>;
  billItemFormsPostSubtotal?: InputMaybe<Array<InputMaybe<BillItemForm>>>;
  billItemFormsPreSubtotal?: InputMaybe<Array<InputMaybe<BillItemForm>>>;
  billModifierForms?: InputMaybe<Array<InputMaybe<BillModifierForm>>>;
  billRuleForms?: InputMaybe<Array<InputMaybe<BillRuleForm>>>;
  billTypeId?: InputMaybe<Scalars['Int']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  deletedBillItemForms?: InputMaybe<Array<InputMaybe<BillItemForm>>>;
  deletedBillRuleForms?: InputMaybe<Array<InputMaybe<BillRuleForm>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  editorId?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  lineItemForms?: InputMaybe<Array<InputMaybe<LineItemForm>>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  tip?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BillItemForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  amountFormulaId?: InputMaybe<Scalars['Int']['input']>;
  billId?: InputMaybe<Scalars['Int']['input']>;
  billItemId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  billStage?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  costItemForm?: InputMaybe<CostItemForm>;
  description?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTaxable?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  maxQuantity?: InputMaybe<Scalars['Float']['input']>;
  maxQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  minQuantity?: InputMaybe<Scalars['Float']['input']>;
  minQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFormulaId?: InputMaybe<Scalars['Int']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type CostItemForm = {
  billId?: InputMaybe<Scalars['Int']['input']>;
  billItemId?: InputMaybe<Scalars['Int']['input']>;
  costId?: InputMaybe<Scalars['Int']['input']>;
  costItemId?: InputMaybe<Scalars['Int']['input']>;
  costItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type BillModifierForm = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountFormulaId?: InputMaybe<Scalars['Int']['input']>;
  billItemId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  estimateSubtotalMax?: InputMaybe<Scalars['Int']['input']>;
  estimateSubtotalMin?: InputMaybe<Scalars['Int']['input']>;
  estimateTotalMax?: InputMaybe<Scalars['Int']['input']>;
  estimateTotalMin?: InputMaybe<Scalars['Int']['input']>;
  isCredit?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFormulaId?: InputMaybe<Scalars['Int']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type BillRuleForm = {
  billItemId?: InputMaybe<Scalars['Int']['input']>;
  billRuleTypeId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionFormulaId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isVisibleToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFormulaId?: InputMaybe<Scalars['Int']['input']>;
  valueFormulaId?: InputMaybe<Scalars['Int']['input']>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LineItemForm = {
  billItemId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isTaxable?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  maxQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  minQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFormulaId?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  priceFormulaId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  quantityTwo?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type ClientForm = {
  billingLocationForm?: InputMaybe<LocationForm>;
  clientId?: InputMaybe<Scalars['Int']['input']>;
  coordinatorId?: InputMaybe<Scalars['Int']['input']>;
  externalIntegrationCustomerId?: InputMaybe<Scalars['Int']['input']>;
  isOkayDuplicatePhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  primaryContactForm?: InputMaybe<ContactForm>;
  primaryLocationForm?: InputMaybe<LocationForm>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
  salespersonId?: InputMaybe<Scalars['Int']['input']>;
};

export type LocationForm = {
  address?: InputMaybe<Scalars['String']['input']>;
  buildingType?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerForm?: InputMaybe<CustomerForm>;
  elevatorReservationTime?: InputMaybe<Scalars['String']['input']>;
  floorNumber?: InputMaybe<Scalars['Int']['input']>;
  hasElevator?: InputMaybe<Scalars['Boolean']['input']>;
  hasLongWalk?: InputMaybe<Scalars['Boolean']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  leadId?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  stairCount?: InputMaybe<Scalars['Int']['input']>;
  stairDescription?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  walkingDistance?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerForm = {
  aliasName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Scalars['JSONString']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactForm = {
  clientId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Scalars['JSONString']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type RecurringTaskForm = {
  cadenceInterval?: InputMaybe<Scalars['Int']['input']>;
  cadenceKind?: InputMaybe<Scalars['String']['input']>;
  cadenceRepeatsOn?: InputMaybe<Scalars['Int']['input']>;
  cadenceRepeatsOnKind?: InputMaybe<Scalars['String']['input']>;
  endThresholdDate?: InputMaybe<Scalars['Date']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  recurringTaskId?: InputMaybe<Scalars['Int']['input']>;
  startThresholdDate?: InputMaybe<Scalars['Date']['input']>;
};

export type StorageProjectForm = {
  additionalSalespersonIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  billingClientForm?: InputMaybe<ClientForm>;
  bookedById?: InputMaybe<Scalars['Int']['input']>;
  clientForm?: InputMaybe<ClientForm>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  coordinatedById?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isSameBillingClient?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeNotes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
  sourceProjectId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  valueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
  warehouseNotes?: InputMaybe<Scalars['String']['input']>;
};

export type ValueForm = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  dataTableVariableId?: InputMaybe<Scalars['Int']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRequiredForCreateProject?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleForCreateProject?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeVariableId?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeVariableId?: InputMaybe<Scalars['Int']['input']>;
  stringValue?: InputMaybe<Scalars['String']['input']>;
  valueId?: InputMaybe<Scalars['Int']['input']>;
  variableFormat?: InputMaybe<Scalars['String']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
  variableIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type ChargePayEngineCreditCardForm = {
  beginPaymentV3Form: BeginPaymentV3Form;
  createCardResponse: CreateCardResponse;
  jobId?: InputMaybe<Scalars['String']['input']>;
  payengineCreditCardForm?: InputMaybe<PayengineCreditCardForm>;
  saveToClientId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateCardResponse = {
  cardData?: InputMaybe<CardDataForm>;
  env?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type CardDataForm = {
  addressZip: Scalars['String']['input'];
  brand: Scalars['String']['input'];
  expMonth: Scalars['Int']['input'];
  expYear: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  last4: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PayengineCreditCardForm = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  payengineCreditCardId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteDocumentV2Form = {
  documentForm?: InputMaybe<DocumentForm>;
  step?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentForm = {
  completionStatus?: InputMaybe<Scalars['String']['input']>;
  documentContentJson?: InputMaybe<Scalars['JSONString']['input']>;
  documentId?: InputMaybe<Scalars['Int']['input']>;
  documentItemForms?: InputMaybe<Array<InputMaybe<DocumentItemForm>>>;
};

export type DocumentItemForm = {
  commercialCatalogForm?: InputMaybe<CommercialCatalogForm>;
  includeCustomerName?: InputMaybe<Scalars['Boolean']['input']>;
  includeSignatureName?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<Scalars['JSONString']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  itemKind?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  valuationCoverageForm?: InputMaybe<ValuationCoverageForm>;
};

export type CommercialCatalogForm = {
  commercialCatalogId?: InputMaybe<Scalars['Int']['input']>;
  commercialEquipmentForms?: InputMaybe<Array<InputMaybe<CommercialEquipmentForm>>>;
  commercialMaterialForms?: InputMaybe<Array<InputMaybe<CommercialMaterialForm>>>;
};

export type CommercialEquipmentForm = {
  commercialCatalogId?: InputMaybe<Scalars['Int']['input']>;
  commercialEquipmentId?: InputMaybe<Scalars['Int']['input']>;
  commercialEquipmentTypeId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  quantityDelivered?: InputMaybe<Scalars['Int']['input']>;
  quantityRequested?: InputMaybe<Scalars['Int']['input']>;
};

export type CommercialMaterialForm = {
  commercialCatalogId?: InputMaybe<Scalars['Int']['input']>;
  commercialMaterialId?: InputMaybe<Scalars['Int']['input']>;
  commercialMaterialTypeId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  quantityDelivered?: InputMaybe<Scalars['Int']['input']>;
  quantityRequested?: InputMaybe<Scalars['Int']['input']>;
};

export type ValuationCoverageForm = {
  amountMinimum?: InputMaybe<Scalars['Int']['input']>;
  amountValue?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  deductible?: InputMaybe<Scalars['Int']['input']>;
  valuationCoverageId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteTimesheetStepForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteEstimatedRangeJobForm = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteInventoryProjectForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  projectForm?: InputMaybe<ProjectForm>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectForm = {
  additionalSalespersonIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  billingClientForm?: InputMaybe<ClientForm>;
  bookedById?: InputMaybe<Scalars['Int']['input']>;
  clientForm?: InputMaybe<ClientForm>;
  collectionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  coordinatedById?: InputMaybe<Scalars['Int']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  customerForm?: InputMaybe<CustomerForm>;
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isSameBillingClient?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  jobForms?: InputMaybe<Array<InputMaybe<JobForm>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  officeNotes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['Int']['input']>;
  sourceProjectId?: InputMaybe<Scalars['Int']['input']>;
  valueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
  warehouseNotes?: InputMaybe<Scalars['String']['input']>;
};

export type JobForm = {
  additionalItems?: InputMaybe<Scalars['JSONString']['input']>;
  additionalNotes?: InputMaybe<Scalars['String']['input']>;
  bookedById?: InputMaybe<Scalars['Int']['input']>;
  confirmationBillingEnd?: InputMaybe<Scalars['String']['input']>;
  confirmationBillingStart?: InputMaybe<Scalars['String']['input']>;
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  crewSize?: InputMaybe<Scalars['String']['input']>;
  customerForm?: InputMaybe<CustomerForm>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dispatchNotes?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  estimateHours1?: InputMaybe<Scalars['Float']['input']>;
  estimateHours2?: InputMaybe<Scalars['Float']['input']>;
  fuelFee?: InputMaybe<Scalars['Int']['input']>;
  hasPacking?: InputMaybe<Scalars['Boolean']['input']>;
  hourlyRate?: InputMaybe<Scalars['Float']['input']>;
  hourMinimum?: InputMaybe<Scalars['Float']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDateFlexible?: InputMaybe<Scalars['Boolean']['input']>;
  isDateRange?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdated?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobRequestId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  locationDistances?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  locationForms?: InputMaybe<Array<InputMaybe<LocationForm>>>;
  moveSize?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  noteFromCustomer?: InputMaybe<Scalars['String']['input']>;
  noteToCustomer?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  numberOfTrucks?: InputMaybe<Scalars['Int']['input']>;
  officeNotes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  packingKind?: InputMaybe<Scalars['String']['input']>;
  parentJobId?: InputMaybe<Scalars['Int']['input']>;
  primaryLaborSourceOrganizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
  scheduledDate?: InputMaybe<Scalars['Date']['input']>;
  sourceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  startTime1?: InputMaybe<Scalars['String']['input']>;
  startTime2?: InputMaybe<Scalars['String']['input']>;
  travelFee?: InputMaybe<Scalars['Float']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  valueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
  warehouseLocationForm?: InputMaybe<LocationForm>;
  warehouseLocationId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteInventoryTutorialForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompleteJobStepForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobStepKind?: InputMaybe<Scalars['String']['input']>;
};

export type CompleteUploadFileForm = {
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type CommercialJobTimesheetForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobUserTimesheetForms?: InputMaybe<Array<InputMaybe<JobUserTimesheetForm>>>;
};

export type JobUserTimesheetForm = {
  jobUserId?: InputMaybe<Scalars['Int']['input']>;
  timeRangeForms?: InputMaybe<Array<InputMaybe<TimeRangeForm>>>;
};

export type TimeRangeForm = {
  end?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmCrewSlotsForJobForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  setCrewSlotsToCrewForms?: InputMaybe<Array<InputMaybe<SetCrewSlotsToCrewForm>>>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type SetCrewSlotsToCrewForm = {
  assignTrucksToAllCrews?: InputMaybe<Scalars['Boolean']['input']>;
  canCreateNewSlot?: InputMaybe<Scalars['Boolean']['input']>;
  crewId?: InputMaybe<Scalars['Int']['input']>;
  crewSlotForms?: InputMaybe<Array<InputMaybe<CrewSlotForm>>>;
  shouldRunUpsertCostsAndBillingEngineAsync?: InputMaybe<Scalars['Boolean']['input']>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type ConfirmJobUsersForJobForm = {
  appInstallId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobUserForms?: InputMaybe<Array<InputMaybe<JobUserForm>>>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type CopyDocumentTemplatesForm = {
  destinationOrganizationSlug?: InputMaybe<Scalars['String']['input']>;
  documentTemplateIdentifiers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceOrganizationSlug?: InputMaybe<Scalars['String']['input']>;
};

export type AccountGrantForm = {
  accountGrantId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  nylasCode?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAndSendConfirmationForProjectForm = {
  emailForm?: InputMaybe<EmailForm>;
  expirationTimeframe?: InputMaybe<Scalars['Float']['input']>;
  expirationTimeframeUnitKind?: InputMaybe<Scalars['String']['input']>;
  isEnabledExpiration?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  stepInputs?: InputMaybe<Array<InputMaybe<ConfirmationStepInput>>>;
};

export type EmailForm = {
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bccEmailRecipientForms?: InputMaybe<Array<InputMaybe<EmailRecipientForm>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  ccEmailRecipientForms?: InputMaybe<Array<InputMaybe<EmailRecipientForm>>>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  documentTemplateKinds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromEmailInbound?: InputMaybe<Scalars['String']['input']>;
  fromNameInbound?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  senderId?: InputMaybe<Scalars['Int']['input']>;
  sentAt?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['Int']['input']>;
  toEmailRecipientForms?: InputMaybe<Array<InputMaybe<EmailRecipientForm>>>;
};

export type EmailRecipientForm = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmationStepInput = {
  actionText?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['String']['input']>;
  documentUuid?: InputMaybe<Scalars['String']['input']>;
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  kind: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  valueTwo?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAttachmentForm = {
  attachmentCategoryKinds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  isVisibleToCrew?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  roomId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAttachmentsForm = {
  createAttachmentForms?: InputMaybe<Array<InputMaybe<CreateAttachmentForm>>>;
};

export type BattleCardForm = {
  battleCardId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  suggestedResponsesJson?: InputMaybe<Scalars['JSONString']['input']>;
  triggerWords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type BillingLibraryForm = {
  baseBillingLibraryId?: InputMaybe<Scalars['Int']['input']>;
  billingLibraryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type BillItemTypeForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  amountFormulaId?: InputMaybe<Scalars['Int']['input']>;
  billingLibraryId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeMoverPositionRateForms?: InputMaybe<
    Array<InputMaybe<BillItemTypeMoverPositionRateForm>>
  >;
  billStage?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTaxable?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  maxQuantity?: InputMaybe<Scalars['Float']['input']>;
  maxQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  minQuantity?: InputMaybe<Scalars['Float']['input']>;
  minQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFormulaId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type BillItemTypeMoverPositionRateForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  amountFormulaId?: InputMaybe<Scalars['Int']['input']>;
  externalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
};

export type BillRuleTypeForm = {
  billingLibraryId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  billRuleTypeId?: InputMaybe<Scalars['Int']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionFormulaId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isVisibleToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFormulaId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  stringValue?: InputMaybe<Scalars['String']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  valueFormulaId?: InputMaybe<Scalars['Int']['input']>;
};

export type BillTypeForm = {
  billingLibraryId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  billRuleTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  billTypeId?: InputMaybe<Scalars['Int']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type CallQueueForm = {
  callQueueId?: InputMaybe<Scalars['Int']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fallbackPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumberForm?: InputMaybe<PhoneNumberForm>;
  queueMemberIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type PhoneNumberForm = {
  callQueueId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberId?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  providerDetails?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ClaimForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  attachmentForms?: InputMaybe<Array<InputMaybe<UpdateAttachmentForm>>>;
  claimId?: InputMaybe<Scalars['Int']['input']>;
  claimStatusId?: InputMaybe<Scalars['Int']['input']>;
  claimTypeId?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type ClaimStatusForm = {
  claimStatusId?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type ClaimTypeForm = {
  claimTypeId?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type CodatIntegrationForm = {
  batchKickoffHour?: InputMaybe<Scalars['Int']['input']>;
  codatAccountReferenceJson?: InputMaybe<Scalars['JSONString']['input']>;
  codatIntegrationId?: InputMaybe<Scalars['Int']['input']>;
  customExternalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  customSalesTaxRateVariableId?: InputMaybe<Scalars['Int']['input']>;
  defaultExternalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  invoiceSectionExternalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  isAutomaticExportEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabledCustomSalesTaxExports?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  kind?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  paymentMethodFeeExternalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  projectTrackingCategorySourceVariableId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeIdsToEnqueueForExportUponInvoiceFinalized?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  projectTypeIdsToEnqueueForExportUponPaymentRecorded?: InputMaybe<
    Array<InputMaybe<Scalars['Int']['input']>>
  >;
  rutterPublicToken?: InputMaybe<Scalars['String']['input']>;
  salesTaxExternalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  shouldExportInvoiceSectionTitles?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tipExternalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
  valuationCoverageExternalInvoiceItemId?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionFormV2 = {
  assignedToType?: InputMaybe<Scalars['String']['input']>;
  collectionId?: InputMaybe<Scalars['Int']['input']>;
  containerId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  estimatedWeight?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  shipmentId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type CommentForm = {
  body?: InputMaybe<Scalars['String']['input']>;
  commentId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  threadId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CommercialEquipmentTypeForm = {
  commercialEquipmentTypeId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
};

export type CommercialMaterialTypeForm = {
  commercialMaterialTypeId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyForm = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  inviteUserForm?: InputMaybe<InviteUserForm>;
  name?: InputMaybe<Scalars['String']['input']>;
  supermoveProductForms?: InputMaybe<Array<InputMaybe<SupermoveProductForm>>>;
};

export type InviteUserForm = {
  confirmPassword?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  names?: InputMaybe<Scalars['JSONString']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type SupermoveProductForm = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  numberOfLicenses?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  supermoveProductId?: InputMaybe<Scalars['Int']['input']>;
};

export type ConfirmationStepsForm = {
  confirmationStepsId?: InputMaybe<Scalars['Int']['input']>;
  defaultExpirationTimeframe?: InputMaybe<Scalars['Float']['input']>;
  defaultExpirationTimeframeUnitKind?: InputMaybe<Scalars['String']['input']>;
  emailTemplateId?: InputMaybe<Scalars['Int']['input']>;
  isEnabledAutomaticExpiration?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  shouldAttachDocumentsInEmail?: InputMaybe<Scalars['Boolean']['input']>;
  stepsJson?: InputMaybe<Scalars['JSONString']['input']>;
};

export type ContainerForm = {
  containerId?: InputMaybe<Scalars['Int']['input']>;
  containerTypeId?: InputMaybe<Scalars['Int']['input']>;
  customLocationForm?: InputMaybe<LocationForm>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  locationKind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  warehouseCoordinateX?: InputMaybe<Scalars['String']['input']>;
  warehouseCoordinateY?: InputMaybe<Scalars['String']['input']>;
  warehouseCoordinateZ?: InputMaybe<Scalars['String']['input']>;
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
};

export type ContainerTypeForm = {
  containerTypeId?: InputMaybe<Scalars['Int']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  dimensionHeight?: InputMaybe<Scalars['Float']['input']>;
  dimensionLength?: InputMaybe<Scalars['Float']['input']>;
  dimensionWidth?: InputMaybe<Scalars['Float']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  weightCapacity?: InputMaybe<Scalars['Float']['input']>;
};

export type CostForm = {
  category?: InputMaybe<Scalars['String']['input']>;
  compensationUserId?: InputMaybe<Scalars['Int']['input']>;
  costId?: InputMaybe<Scalars['Int']['input']>;
  costSectionForms?: InputMaybe<Array<InputMaybe<CostSectionForm>>>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type CostSectionForm = {
  billItemForms?: InputMaybe<Array<InputMaybe<BillItemForm>>>;
  costItemForms?: InputMaybe<Array<InputMaybe<CostItemForm>>>;
};

export type CostItemTypeForm = {
  billingLibraryId?: InputMaybe<Scalars['Int']['input']>;
  billItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  billTypeId?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  costItemTypeId?: InputMaybe<Scalars['Int']['input']>;
  costItemTypeRateForms?: InputMaybe<Array<InputMaybe<CostItemTypeRateForm>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type CostItemTypeRateForm = {
  costItemTypeRateId?: InputMaybe<Scalars['Int']['input']>;
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
};

export type EmployeeForm = {
  branchCode?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Scalars['JSONString']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  moverPositionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  names?: InputMaybe<Scalars['JSONString']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCrewsForJobForm = {
  crewForms?: InputMaybe<Array<InputMaybe<CrewForm>>>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type CrewForm = {
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  numberOfRequiredMovers?: InputMaybe<Scalars['Int']['input']>;
  numberOfRequiredTrucks?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CreateCrewWithSlotsAndTrucksForm = {
  crewForm?: InputMaybe<CrewForm>;
  setCrewSlotsToCrewForm?: InputMaybe<SetCrewSlotsToCrewForm>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardForm = {
  category?: InputMaybe<Scalars['String']['input']>;
  dashboardCategoryId?: InputMaybe<Scalars['Int']['input']>;
  dashboardId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exploDashboardEmbedId?: InputMaybe<Scalars['String']['input']>;
  exploVariables?: InputMaybe<Scalars['String']['input']>;
  helpArticleUrl?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type DashboardCategoryForm = {
  dashboardCategoryId?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  replaceWithDashboardCategoryId?: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardFromGlobalDashboardForm = {
  globalDashboardId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardTagForm = {
  dashboardTagId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentTemplateV2Form = {
  category?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentTemplateV2Id?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDefaultConfirmationDocumentTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  isDefaultQuoteDocumentTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  shouldEmailDocumentToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateDriverInventoryForJobForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
};

export type EmailTemplateForm = {
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  bccEmailTemplateRecipientForms?: InputMaybe<Array<InputMaybe<EmailTemplateRecipientForm>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  bodyKind?: InputMaybe<Scalars['String']['input']>;
  ccEmailTemplateRecipientForms?: InputMaybe<Array<InputMaybe<EmailTemplateRecipientForm>>>;
  emailTemplateId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  toEmailTemplateRecipientForms?: InputMaybe<Array<InputMaybe<EmailTemplateRecipientForm>>>;
};

export type EmailTemplateRecipientForm = {
  email?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type FormulaForm = {
  astJson?: InputMaybe<Scalars['JSONString']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type GlobalDashboardForm = {
  dashboardCategoryForm?: InputMaybe<DashboardCategoryForm>;
  dashboardTagForms?: InputMaybe<Array<InputMaybe<DashboardTagForm>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  exploDashboardEmbedId?: InputMaybe<Scalars['String']['input']>;
  exploVariables?: InputMaybe<Scalars['String']['input']>;
  globalDashboardId?: InputMaybe<Scalars['Int']['input']>;
  helpArticleUrl?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type InventoryLibraryForm = {
  defaultProjectTypeInventorySettingsForms?: InputMaybe<
    Array<InputMaybe<DefaultProjectTypeInventorySettingsForm>>
  >;
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  newDefaultInventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type DefaultProjectTypeInventorySettingsForm = {
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  surveyMethodId?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryReviewForm = {
  comment?: InputMaybe<Scalars['String']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobEventForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobRequestForm = {
  additionalItems?: InputMaybe<Scalars['JSONString']['input']>;
  bookedById?: InputMaybe<Scalars['Int']['input']>;
  customerForm?: InputMaybe<CustomerForm>;
  isFlexible?: InputMaybe<Scalars['Boolean']['input']>;
  locationDistances?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  locationForms?: InputMaybe<Array<InputMaybe<LocationForm>>>;
  moveSize?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  preferredCrewSize?: InputMaybe<Scalars['String']['input']>;
  preferredDate?: InputMaybe<Scalars['String']['input']>;
  preferredStartTime?: InputMaybe<Scalars['String']['input']>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
};

export type JobTypeForm = {
  billTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  setsDeliveryDates?: InputMaybe<Scalars['Boolean']['input']>;
  setsLoadDates?: InputMaybe<Scalars['Boolean']['input']>;
  setsPackDates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateMoverPositionForm = {
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type PayrollReportForm = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  payrollReportId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type PlaybookTemplateForm = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  playbookTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectTypeForm = {
  billingLibraryId?: InputMaybe<Scalars['Int']['input']>;
  billTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  category?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  defaultInvoiceDocumentTemplateId?: InputMaybe<Scalars['Int']['input']>;
  defaultInvoiceEmailTemplateId?: InputMaybe<Scalars['Int']['input']>;
  defaultInvoicePaymentTerm?: InputMaybe<Scalars['String']['input']>;
  defaultInvoiceReceiptEmailTemplateId?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isJobLevelBilling?: InputMaybe<Scalars['Boolean']['input']>;
  isLongDistance?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type RoomForm = {
  description?: InputMaybe<Scalars['String']['input']>;
  floorNumber?: InputMaybe<Scalars['Int']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['Int']['input']>;
  roomTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type SlotForm = {
  dayId?: InputMaybe<Scalars['Int']['input']>;
};

export type SlotTypeForm = {
  driverId?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  slotTypeId?: InputMaybe<Scalars['Int']['input']>;
  truckId?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskForm = {
  actionKind?: InputMaybe<Scalars['String']['input']>;
  assignedToTeamId?: InputMaybe<Scalars['Int']['input']>;
  assignedToUserId?: InputMaybe<Scalars['Int']['input']>;
  callId?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  discussedAt?: InputMaybe<Scalars['Int']['input']>;
  dueAtDate?: InputMaybe<Scalars['Date']['input']>;
  dueAtTime?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  modifiedById?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  taskId?: InputMaybe<Scalars['Int']['input']>;
  taskTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTaskFromTemplateForm = {
  actionKind?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  taskTemplateId?: InputMaybe<Scalars['Int']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type TaskTemplateForm = {
  actionKind?: InputMaybe<Scalars['String']['input']>;
  assignedToRole?: InputMaybe<Scalars['String']['input']>;
  assignedToTeamId?: InputMaybe<Scalars['Int']['input']>;
  assignedToUserId?: InputMaybe<Scalars['Int']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  dueAtTime?: InputMaybe<Scalars['String']['input']>;
  dueInUnits?: InputMaybe<Scalars['Float']['input']>;
  dueInUnitType?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  playbookTemplateId?: InputMaybe<Scalars['Int']['input']>;
  taskName?: InputMaybe<Scalars['String']['input']>;
  taskTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamForm = {
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  teamId?: InputMaybe<Scalars['Int']['input']>;
  teamMemberForms?: InputMaybe<Array<InputMaybe<TeamMemberForm>>>;
};

export type TeamMemberForm = {
  teamMemberId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type TextMessageTemplateForm = {
  body?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumberRecipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectUserRecipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  systemTemplateKind?: InputMaybe<Scalars['String']['input']>;
  teamIdRecipients?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  textMessageTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTimesheetBillableBlocksForm = {
  createTimesheetBlockForms?: InputMaybe<Array<CreateTimesheetBlockForm>>;
  timesheetBillableEntryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateTimesheetBlockForm = {
  kind?: InputMaybe<Scalars['String']['input']>;
  rangeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  rangeTo?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateTimesheetPayrollBlocksForm = {
  createTimesheetBlockForms?: InputMaybe<Array<CreateTimesheetBlockForm>>;
  timesheetPayrollEntryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateTrainingProjectForm = {
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  isDateRange?: InputMaybe<Scalars['Boolean']['input']>;
  jobStatus?: InputMaybe<Scalars['String']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  jobValueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  primaryLaborSourceOrganizationId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  projectValueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
  sourceId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type TripForm = {
  driverId?: InputMaybe<Scalars['Int']['input']>;
  driverNotes?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  endLocationForm?: InputMaybe<LocationForm>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeNotes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  shipmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  startLocationForm?: InputMaybe<LocationForm>;
  status?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
  truckIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type CreateTwilioPhoneNumberForUserForm = {
  userId: Scalars['String']['input'];
};

export type VariableForm = {
  category?: InputMaybe<Scalars['String']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  dataTableVariableForm?: InputMaybe<DataTableVariableForm>;
  description?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  formulaIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDerived?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleForCreateProject?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  variableOptionForms?: InputMaybe<Array<InputMaybe<VariableOptionForm>>>;
};

export type DataTableVariableForm = {
  dataTableForm?: InputMaybe<DataTableForm>;
  dataTableId?: InputMaybe<Scalars['Int']['input']>;
  dataTableVariableId?: InputMaybe<Scalars['Int']['input']>;
  inputVariableId?: InputMaybe<Scalars['Int']['input']>;
  shouldUseBreakpoint?: InputMaybe<Scalars['Boolean']['input']>;
  tableAccessKind?: InputMaybe<Scalars['String']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type DataTableForm = {
  createdById?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['Upload']['input']>;
  dataTableId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  sourceId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type VariableOptionForm = {
  key?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type WarehouseForm = {
  createdById?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  locationForm?: InputMaybe<LocationForm>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  warehouseId?: InputMaybe<Scalars['Int']['input']>;
};

export type WebhookForm = {
  description?: InputMaybe<Scalars['String']['input']>;
  headers?: InputMaybe<Scalars['JSONString']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  webhookId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowForm = {
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobTypeIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectSourceIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  projectTypeIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tagIdList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  triggerDaysBefore?: InputMaybe<Scalars['Int']['input']>;
  triggerHour?: InputMaybe<Scalars['Int']['input']>;
  triggerIdentifier?: InputMaybe<Scalars['String']['input']>;
  triggerVariableIdentifier?: InputMaybe<Scalars['String']['input']>;
  workflowId?: InputMaybe<Scalars['Int']['input']>;
  workflowStepForms?: InputMaybe<Array<InputMaybe<WorkflowStepForm>>>;
};

export type WorkflowStepForm = {
  assignOfficeStaffActionForm?: InputMaybe<AssignOfficeStaffActionForm>;
  assignToBranchActionForm?: InputMaybe<AssignToBranchActionForm>;
  createAndSendConfirmationForProjectActionForm?: InputMaybe<CreateAndSendConfirmationForProjectActionForm>;
  createTaskFromTemplateForm?: InputMaybe<CreateTaskFromTemplateForm>;
  delayWorkflowActionForm?: InputMaybe<DelayWorkflowActionForm>;
  findTaskFromPreviousStepActionForm?: InputMaybe<FindTaskFromPreviousStepActionForm>;
  getDataAndStopIfActionForm?: InputMaybe<GetDataAndStopIfActionForm>;
  getJobDataActionForm?: InputMaybe<GetJobDataActionForm>;
  getProjectDataActionForm?: InputMaybe<GetProjectDataActionForm>;
  invokeWebhookActionForm?: InputMaybe<InvokeWebhookActionForm>;
  order?: InputMaybe<Scalars['Int']['input']>;
  removeTagAssignmentActionForm?: InputMaybe<RemoveTagAssignmentActionForm>;
  sendEmailFromTemplateActionForm?: InputMaybe<SendEmailFromTemplateActionForm>;
  sendSmsFromTemplateActionForm?: InputMaybe<SendSmsFromTemplateActionForm>;
  sendSmsV2FromTemplateActionForm?: InputMaybe<SendSmsV2FromTemplateActionForm>;
  sendVirtualWalkthroughActionForm?: InputMaybe<SendVirtualWalkthroughActionForm>;
  stopIfActionForm?: InputMaybe<StopIfActionForm>;
  toggleTaskPropertyActionForm?: InputMaybe<ToggleTaskPropertyActionForm>;
  updateTagAssignmentActionForm?: InputMaybe<UpdateTagAssignmentActionForm>;
  updateTaskActionForm?: InputMaybe<UpdateTaskActionForm>;
  workflowStepActionKind?: InputMaybe<Scalars['String']['input']>;
};

export type AssignOfficeStaffActionForm = {
  roundRobinUserIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  specificUserId?: InputMaybe<Scalars['Int']['input']>;
  userRoleKind?: InputMaybe<Scalars['String']['input']>;
  workflowProjectAssignmentType?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type AssignToBranchActionForm = {
  defaultOrganizationId?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAndSendConfirmationForProjectActionForm = {
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type DelayWorkflowActionForm = {
  delayUnits?: InputMaybe<Scalars['Float']['input']>;
  delayUnitType?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type FindTaskFromPreviousStepActionForm = {
  taskIdReferencePath?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GetDataAndStopIfActionForm = {
  jobTagExclusionList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobTagInclusionList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectStatusDoesNotEqual?: InputMaybe<Scalars['String']['input']>;
  projectStatusEquals?: InputMaybe<Scalars['String']['input']>;
  projectTagExclusionList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  projectTagInclusionList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taskIsCompleted?: InputMaybe<Scalars['String']['input']>;
  taskIsNotCompleted?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GetJobDataActionForm = {
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type GetProjectDataActionForm = {
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type InvokeWebhookActionForm = {
  authToken?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  headers?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveTagAssignmentActionForm = {
  jobId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type SendEmailFromTemplateActionForm = {
  emailTemplateId?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type SendSmsFromTemplateActionForm = {
  textMessageTemplateId?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type SendSmsV2FromTemplateActionForm = {
  recipientKind?: InputMaybe<Scalars['String']['input']>;
  recipientTeamIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  recipientUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  textMessageTemplateCategory?: InputMaybe<Scalars['String']['input']>;
  textMessageTemplateId?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type SendVirtualWalkthroughActionForm = {
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type StopIfActionForm = {
  comparatorType?: InputMaybe<Scalars['String']['input']>;
  comparatorValue?: InputMaybe<Scalars['String']['input']>;
  propertyDataType?: InputMaybe<Scalars['String']['input']>;
  propertyName?: InputMaybe<Scalars['String']['input']>;
  stepReferenceKind?: InputMaybe<Scalars['String']['input']>;
  stepReferencePath?: InputMaybe<Scalars['String']['input']>;
  stopActionForm?: InputMaybe<StopActionForm>;
  transformWorkflowActionForm?: InputMaybe<TransformWorkflowActionForm>;
};

export type StopActionForm = {
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type TransformWorkflowActionForm = {
  transformPath?: InputMaybe<Scalars['String']['input']>;
  transformWorkflowFormulaString?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type ToggleTaskPropertyActionForm = {
  taskIdReferencePath?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTagAssignmentActionForm = {
  jobId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTaskActionForm = {
  assignedToRole?: InputMaybe<Scalars['String']['input']>;
  assignedToUserId?: InputMaybe<Scalars['Int']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  dueAtTime?: InputMaybe<Scalars['String']['input']>;
  dueInUnits?: InputMaybe<Scalars['Float']['input']>;
  dueInUnitType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  taskIdReferencePath?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepUuid?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAttachmentForm = {
  attachmentId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSinglePropertyForm = {
  id?: InputMaybe<Scalars['Int']['input']>;
  integerValue?: InputMaybe<Scalars['Int']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  stringValue?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyMethodForm = {
  defaultProjectTypeInventorySettingsForms?: InputMaybe<
    Array<InputMaybe<DefaultProjectTypeInventorySettingsForm>>
  >;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  newDefaultSurveyMethodId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  surveyMethodId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobLocationForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobLocationDistances?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
};

export type DeleteMoverPositionForm = {
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectLocationsForm = {
  destinationLocationId?: InputMaybe<Scalars['Int']['input']>;
  locationForms?: InputMaybe<Array<InputMaybe<LocationForm>>>;
  originLocationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectTypeRoomTypeForm = {
  index?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeRoomTypeId?: InputMaybe<Scalars['Int']['input']>;
  roomTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectTypeVariableForm = {
  defaultAmount?: InputMaybe<Scalars['Float']['input']>;
  defaultStringValue?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRequiredForCreateProject?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleForCreateProject?: InputMaybe<Scalars['Boolean']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeVariableId?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type TagAssignmentForm = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  tagAssignmentId?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['Int']['input']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type DequeueInvoiceForm = {
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
};

export type DuplicateGlobalDashboardForm = {
  globalDashboardId?: InputMaybe<Scalars['Int']['input']>;
};

export type EmailPreviewForm = {
  body?: InputMaybe<Scalars['String']['input']>;
  bodyKind?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type EnablePayEngineGatewayForm = {
  gatewayUuid: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
};

export type EvaluateAndReplaceVariablesForm = {
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmationForm = {
  confirmationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ExportLeadForm = {
  leadExportConfigurationId?: InputMaybe<Scalars['Int']['input']>;
  leadId?: InputMaybe<Scalars['Int']['input']>;
  supermoveJobTypeId?: InputMaybe<Scalars['Int']['input']>;
  supermoveProjectTypeId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type GenerateAiEmailForm = {
  callId?: InputMaybe<Scalars['Int']['input']>;
  taskId?: InputMaybe<Scalars['Int']['input']>;
};

export type GenerateAiTextMessageForm = {
  callId?: InputMaybe<Scalars['Int']['input']>;
  taskId?: InputMaybe<Scalars['Int']['input']>;
};

export type GenerateCrewDocumentsForJobForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
};

export type GenerateCustomDocumentsForJobForm = {
  documentTemplateIdentifiers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type GenerateMoverAppLoginCodeForm = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateSalesAppLoginCodeForm = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberOrEmail?: InputMaybe<Scalars['String']['input']>;
};

export type ChatCompletionForm = {
  callFunctionDefinition?: InputMaybe<Scalars['String']['input']>;
  callId?: InputMaybe<Scalars['Int']['input']>;
  chatCompletionId?: InputMaybe<Scalars['Int']['input']>;
  inputSystemMessage?: InputMaybe<Scalars['String']['input']>;
  inputUserMessage?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  openAiModelIdentifier?: InputMaybe<Scalars['String']['input']>;
  output?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GetShouldWorkflowStopForm = {
  jobTagExclusionList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  jobTagInclusionList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  projectStatusDoesNotEqual?: InputMaybe<Scalars['String']['input']>;
  projectStatusEquals?: InputMaybe<Scalars['String']['input']>;
  projectTagExclusionList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  projectTagInclusionList?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  taskIsCompleted?: InputMaybe<Scalars['String']['input']>;
  taskIsNotCompleted?: InputMaybe<Scalars['String']['input']>;
  workflowRunStepIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type HangupCallForm = {
  callId?: InputMaybe<Scalars['Int']['input']>;
};

export type ImpersonateUserForm = {
  userId?: InputMaybe<Scalars['Int']['input']>;
  viewerId?: InputMaybe<Scalars['Int']['input']>;
  viewingOrganizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ImportCallForm = {
  date?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  otherPartyNumber?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ImportExternalInvoiceItemMappingsForm = {
  codatIntegrationId?: InputMaybe<Scalars['Int']['input']>;
  fileId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ImportInventoryLibraryCategoriesForm = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
};

export type ImportInventoryLibraryItemTagsForm = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
};

export type ImportInventoryLibraryItemTypesForm = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
};

export type ImportInventoryLibraryRoomTypesForm = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
};

export type ImportOrganizationProjectsForm = {
  fileId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type InitiateOutgoingCallForm = {
  fromUserId: Scalars['String']['input'];
  toNumber: Scalars['String']['input'];
};

export type InviteSalesAppUserForm = {
  invitedById?: InputMaybe<Scalars['Int']['input']>;
  phoneNumberForm?: InputMaybe<PhoneNumberForm>;
  supermoveProductId?: InputMaybe<Scalars['Int']['input']>;
  userForm?: InputMaybe<UserForm>;
};

export type UserForm = {
  email?: InputMaybe<Scalars['String']['input']>;
  forwardingPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  moverPositionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  names?: InputMaybe<Scalars['JSONString']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  salesAppRole?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobConfirmCrewForm = {
  appInstallId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobUserForms?: InputMaybe<Array<InputMaybe<JobUserForm>>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobConfirmTrucksForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobTruckForms?: InputMaybe<Array<InputMaybe<JobTruckForm>>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobTruckForm = {
  crewId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobTruckId?: InputMaybe<Scalars['Int']['input']>;
  truckId?: InputMaybe<Scalars['Int']['input']>;
};

export type KillBatchForm = {
  batchId?: InputMaybe<Scalars['Int']['input']>;
};

export type LogInAdminForm = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ManuallyCompleteProjectConfirmationForm = {
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type ManuallyTriggerWorkflowForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  workflowId?: InputMaybe<Scalars['Int']['input']>;
};

export type InAppNotificationForm = {
  inAppNotificationId?: InputMaybe<Scalars['Int']['input']>;
};

export type MoverAppLoginForm = {
  code?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MoverAppSelectAccountForm = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type PrepareInventoryProjectForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
};

export type StartInventoryProjectForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  projectForm?: InputMaybe<ProjectForm>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type PreviewBillForm = {
  billForms?: InputMaybe<Array<InputMaybe<BillForm>>>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type PreviewBillItemForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  amountFormulaId?: InputMaybe<Scalars['Int']['input']>;
  maxQuantity?: InputMaybe<Scalars['Float']['input']>;
  maxQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  minQuantity?: InputMaybe<Scalars['Float']['input']>;
  minQuantityFormulaId?: InputMaybe<Scalars['Int']['input']>;
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameFormulaId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  valueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
};

export type DocumentTemplateVersionForm = {
  createdById?: InputMaybe<Scalars['Int']['input']>;
  documentContentJson?: InputMaybe<Scalars['JSONString']['input']>;
  documentContentJsonForm?: InputMaybe<DocumentContentJsonForm>;
  documentTemplateId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentContentJsonForm = {
  documentItems?: InputMaybe<Array<InputMaybe<DocumentItemForm>>>;
};

export type TranscriptForm = {
  audioS3Url?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  liveTranscriptText?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<Scalars['String']['input']>;
  speechmaticsSessionId?: InputMaybe<Scalars['String']['input']>;
  transcriptS3Url?: InputMaybe<Scalars['String']['input']>;
  transcriptText?: InputMaybe<Scalars['String']['input']>;
};

export type RecordPaymentForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromAccountId?: InputMaybe<Scalars['Int']['input']>;
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['Int']['input']>;
  platformAccountId?: InputMaybe<Scalars['Int']['input']>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
  toAccountId?: InputMaybe<Scalars['Int']['input']>;
};

export type RemoveContainerFromProjectForm = {
  containerId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type RemoveContainersFromProjectForm = {
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type RemoveShipmentsFromTripForm = {
  shipmentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReorderBillsForm = {
  billForms?: InputMaybe<Array<InputMaybe<BillForm>>>;
  reorderBillForms?: InputMaybe<Array<InputMaybe<ReorderBillForm>>>;
};

export type ReorderBillForm = {
  billId?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
};

export type ReorderClaimStatusesForm = {
  claimStatusForms?: InputMaybe<Array<InputMaybe<ClaimStatusForm>>>;
};

export type ReorderDashboardCategoriesForm = {
  dashboardCategoryForms?: InputMaybe<Array<InputMaybe<DashboardCategoryForm>>>;
};

export type ReorderPlaybookTaskTemplatesForm = {
  taskTemplateForms?: InputMaybe<Array<InputMaybe<TaskTemplateForm>>>;
};

export type ReorderProjectTypeRoomTypesForm = {
  projectTypeRoomTypeForms?: InputMaybe<Array<InputMaybe<ProjectTypeRoomTypeForm>>>;
};

export type ReorderReportDashboardsForm = {
  reportDashboardForms?: InputMaybe<Array<InputMaybe<DashboardForm>>>;
};

export type ReorderSlotsForm = {
  reorderSlotForms?: InputMaybe<Array<InputMaybe<ReorderSlotForm>>>;
};

export type ReorderSlotForm = {
  index?: InputMaybe<Scalars['Int']['input']>;
  slotId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReorderSurveyMethodsForm = {
  surveyMethodForms?: InputMaybe<Array<InputMaybe<SurveyMethodForm>>>;
};

export type RequestUploadFileForm = {
  creatorId?: InputMaybe<Scalars['Int']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  mimetype?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type ResendMoverAppInvitationTextForm = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ResendSalesAppInviteForm = {
  invitedById?: InputMaybe<Scalars['Int']['input']>;
  productUserId?: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardLinkedToGlobalDashboardForm = {
  dashboardCategoryId?: InputMaybe<Scalars['Int']['input']>;
  dashboardId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exploVariables?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesAppLoginForm = {
  code?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberOrEmail?: InputMaybe<Scalars['String']['input']>;
};

export type SalesAppSelectAccountForm = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type SavePayEngineCreditCardForm = {
  clientId?: InputMaybe<Scalars['Int']['input']>;
  createCardResponse?: InputMaybe<CreateCardResponse>;
  payengineCreditCardForm?: InputMaybe<PayengineCreditCardForm>;
};

export type SendEmailFromTemplateForProjectForm = {
  emailTemplateId?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type SendEmailForJobForm = {
  attachmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  body?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type SendInvoiceToCodatForm = {
  invoiceId?: InputMaybe<Scalars['Int']['input']>;
};

export type SendTextMessageFromTemplateForProjectForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  textMessageTemplateId?: InputMaybe<Scalars['String']['input']>;
};

export type SendTextMessageFromTemplateForUserKindForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  recipientKind?: InputMaybe<Scalars['String']['input']>;
  recipientTeamIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  recipientUserIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  textMessageTemplateId?: InputMaybe<Scalars['String']['input']>;
};

export type SendTextMessageToTeamForm = {
  body?: InputMaybe<Scalars['String']['input']>;
  isManual?: InputMaybe<Scalars['Boolean']['input']>;
  senderId?: InputMaybe<Scalars['Int']['input']>;
  teamId?: InputMaybe<Scalars['Int']['input']>;
  textMessageTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type SendTextMessageToUserForm = {
  body?: InputMaybe<Scalars['String']['input']>;
  isManual?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  recipientId?: InputMaybe<Scalars['Int']['input']>;
  senderId?: InputMaybe<Scalars['Int']['input']>;
  textMessageTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectIsTestForm = {
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type SkipDocumentV2Form = {
  documentId?: InputMaybe<Scalars['Int']['input']>;
  isSkipAll?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SortDaySlotsForm = {
  dayId?: InputMaybe<Scalars['Int']['input']>;
  sortOption?: InputMaybe<Scalars['String']['input']>;
};

export type StartEstimatedRangeJobForm = {
  confirmJobUsersForJobForm?: InputMaybe<ConfirmJobUsersForJobForm>;
  date?: InputMaybe<Scalars['String']['input']>;
  parentJobId?: InputMaybe<Scalars['Int']['input']>;
};

export type SyncAllCommercialCatalogBillsForm = {
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type SyncInventoryForm = {
  densityFactor?: InputMaybe<Scalars['Float']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  syncRoomForms?: InputMaybe<Array<InputMaybe<SyncRoomForm>>>;
};

export type SyncRoomForm = {
  description?: InputMaybe<Scalars['String']['input']>;
  floorNumber?: InputMaybe<Scalars['Int']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomTypeId?: InputMaybe<Scalars['Int']['input']>;
  syncCollectionForm?: InputMaybe<SyncCollectionForm>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type SyncCollectionForm = {
  syncItemForms?: InputMaybe<Array<InputMaybe<SyncItemForm>>>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type SyncItemForm = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDerivedWeight?: InputMaybe<Scalars['Boolean']['input']>;
  itemTypeId?: InputMaybe<Scalars['Int']['input']>;
  leaveCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  takeCount?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type InventoryForm = {
  densityFactor?: InputMaybe<Scalars['Float']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
};

export type SettingsForm = {
  aiCallActionItemsPromptExcludeList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  aiCallSummaryPromptExcludeList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  aiCallSummaryPromptIncludeList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crewVisibleJobsByProjectStatus?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  crewVisibleJobsForMover?: InputMaybe<Scalars['String']['input']>;
  isCrewChargeSavedCardEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCalendar?: InputMaybe<Scalars['String']['input']>;
  settingsId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
  visibleCalendarStatuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ToggleWorkflowIsActiveForm = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  workflowId?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeRangeInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  endIsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  endName?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  startIsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  startName?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['String']['input']>;
};

export type TranferProjectForm = {
  existingProjectId?: InputMaybe<Scalars['Int']['input']>;
  projectForm?: InputMaybe<ProjectForm>;
};

export type UpdateAttachmentsForm = {
  updateAttachmentForms?: InputMaybe<Array<InputMaybe<UpdateAttachmentForm>>>;
};

export type UpdateBillItemAndValuesForm = {
  billItemForm?: InputMaybe<BillItemForm>;
  updateValuesForm?: InputMaybe<UpdateValuesForm>;
};

export type UpdateValuesForm = {
  editorId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  valueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
};

export type CollectionForm = {
  collectionId?: InputMaybe<Scalars['Int']['input']>;
  itemForms?: InputMaybe<Array<InputMaybe<ItemForm>>>;
};

export type ItemForm = {
  collectionId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDerivedWeight?: InputMaybe<Scalars['Boolean']['input']>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemTypeId?: InputMaybe<Scalars['Int']['input']>;
  leaveCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  takeCount?: InputMaybe<Scalars['Int']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyOrganizationsForm = {
  organizationForms?: InputMaybe<Array<InputMaybe<OrganizationForm>>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationForm = {
  businessAddress?: InputMaybe<Scalars['String']['input']>;
  businessFoundingDate?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  businessStory?: InputMaybe<Scalars['String']['input']>;
  businessValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTabletSettingsForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  organizationTabletSettingsForms?: InputMaybe<Array<InputMaybe<OrganizationTabletSettingsForm>>>;
};

export type OrganizationTabletSettingsForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
};

export type CompensationReportForm = {
  compensationReportId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  payrollReportId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateContainerInDatesForProjectForm = {
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  inDate?: InputMaybe<Scalars['Date']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateContainerOutDatesForProjectForm = {
  containerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  outDate?: InputMaybe<Scalars['Date']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerInventoryForm = {
  accessPointHasElevator?: InputMaybe<Scalars['Boolean']['input']>;
  accessPointHasStairs?: InputMaybe<Scalars['Boolean']['input']>;
  accessPointWalkTime?: InputMaybe<Scalars['String']['input']>;
  accessPointWalkTimeDetails?: InputMaybe<Scalars['String']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerRoomForm = {
  canSkipPhotos?: InputMaybe<Scalars['Boolean']['input']>;
  customerNotes?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['Int']['input']>;
};

export type DayForm = {
  dayId?: InputMaybe<Scalars['Int']['input']>;
  isPackingJobsLocked?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notesUpdatedById?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGlobalDashboardStatusForm = {
  globalDashboardId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInventoryIsFinalizedStatusForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  isFinalized?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryKindForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  projectForm?: InputMaybe<ProjectForm>;
  shouldSendSms?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type InventoryRoomsForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  roomItemsForms?: InputMaybe<Array<InputMaybe<RoomItemsForm>>>;
};

export type RoomItemsForm = {
  collectionId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemForms?: InputMaybe<Array<InputMaybe<ItemFormV2>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['Int']['input']>;
  roomTypeId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type ItemFormV2 = {
  collectionId?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDerivedWeight?: InputMaybe<Scalars['Boolean']['input']>;
  isVoid?: InputMaybe<Scalars['Boolean']['input']>;
  itemAttachmentForms?: InputMaybe<Array<InputMaybe<ItemAttachmentForm>>>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemNumber?: InputMaybe<Scalars['String']['input']>;
  itemTagItemForms?: InputMaybe<Array<InputMaybe<ItemTagItemForm>>>;
  itemTypeId?: InputMaybe<Scalars['Int']['input']>;
  leaveCount?: InputMaybe<Scalars['Int']['input']>;
  lotNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  takeCount?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ItemAttachmentForm = {
  attachmentId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['Int']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemUuid?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type ItemTagItemForm = {
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemId?: InputMaybe<Scalars['Int']['input']>;
  itemTagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  itemTagItemId?: InputMaybe<Scalars['Int']['input']>;
  itemUuid?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type InventorySurveyMethodForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  surveyMethodId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInventoryToVirtualWalkthroughForm = {
  inventoryId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateInventoryToVirtualWalkthroughAutomationForm = {
  createdById?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobRateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type JobDispatchInfoForm = {
  crewSize?: InputMaybe<Scalars['String']['input']>;
  estimateHours1?: InputMaybe<Scalars['Float']['input']>;
  estimateHours2?: InputMaybe<Scalars['Float']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  numberOfTrucks?: InputMaybe<Scalars['Int']['input']>;
  startTime1?: InputMaybe<Scalars['String']['input']>;
  startTime2?: InputMaybe<Scalars['String']['input']>;
};

export type JobNotesForm = {
  additionalNotes?: InputMaybe<Scalars['String']['input']>;
  dispatchNotes?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  noteFromCustomer?: InputMaybe<Scalars['String']['input']>;
  noteToCustomer?: InputMaybe<Scalars['String']['input']>;
  officeNotes?: InputMaybe<Scalars['String']['input']>;
};

export type JobPrimaryStatusForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJobUserForm = {
  jobUserId?: InputMaybe<Scalars['Int']['input']>;
  moverPositionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  primaryMoverPositionId?: InputMaybe<Scalars['Int']['input']>;
};

export type LeadStageForm = {
  leadStageId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  playbookTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateMoverPositionForm = {
  isDriver?: InputMaybe<Scalars['Boolean']['input']>;
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMultibranchAdminViewingOrganizationForm = {
  userId?: InputMaybe<Scalars['Int']['input']>;
  viewingOrganizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOneOrManyTaskPropertiesForm = {
  assignedToUserId?: InputMaybe<Scalars['Int']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  dueAtTime?: InputMaybe<Scalars['String']['input']>;
  dueInUnits?: InputMaybe<Scalars['Float']['input']>;
  dueInUnitType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationBusinessInfoAddressForm = {
  businessAddress?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  warehouseAddress?: InputMaybe<Scalars['String']['input']>;
  warehouseLatitude?: InputMaybe<Scalars['Float']['input']>;
  warehouseLongitude?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationBusinessInfoContactForm = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  businessSignature?: InputMaybe<Scalars['String']['input']>;
  businessWebsite?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationBusinessInfoHoursForm = {
  businessHours?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationBusinessInfoLicenseForm = {
  businessLicense?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationInventorySettingsForm = {
  defaultDensityFactor?: InputMaybe<Scalars['Float']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationLocationTypesForm = {
  locationTypeForms?: InputMaybe<Array<InputMaybe<LocationTypeForm>>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type LocationTypeForm = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationLogoForm = {
  logoFileId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationMoverNotificationSettingsForm = {
  allowEmployeeText?: InputMaybe<Scalars['Boolean']['input']>;
  allowMoverAcceptDeclineJob?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  sendReminderSetSchedule?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganizationNotificationEmailsForm = {
  notificationEmails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationOfficePaymentMethodsForm = {
  officePaymentMethods?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationPaymentFeesForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  paymentFeeForms?: InputMaybe<Array<InputMaybe<PaymentFeeForm>>>;
};

export type PaymentFeeForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganizationReferralSourcesForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  referralSourceForms?: InputMaybe<Array<InputMaybe<ReferralSourceForm>>>;
};

export type ReferralSourceForm = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationReplyToForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  replyToKind?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationSalesTaxForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  salesTaxRate?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOrganizationsForGlobalDashboardForm = {
  globalDashboardId?: InputMaybe<Scalars['Int']['input']>;
  organizationIdsToDisable?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  organizationIdsToEnable?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type OrganizationSuppliesForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  suppliesForm?: InputMaybe<SuppliesForm>;
};

export type SuppliesForm = {
  supplyForms?: InputMaybe<Array<InputMaybe<SupplyForm>>>;
};

export type SupplyForm = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationTipOptionsForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  tipOptionForms?: InputMaybe<Array<InputMaybe<TipOptionForm>>>;
};

export type TipOptionForm = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationVirtualWalkthroughSettingsForm = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  virtualWalkthroughPhotoFileId?: InputMaybe<Scalars['Int']['input']>;
};

export type PayableUserDayForm = {
  payableItemForms?: InputMaybe<Array<InputMaybe<PayableItemForm>>>;
  payableUserDayId?: InputMaybe<Scalars['Int']['input']>;
};

export type PayableItemForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  reportMoveId?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type TimesheetPayrollEntriesForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePlaybookForCallForm = {
  callId?: InputMaybe<Scalars['Int']['input']>;
  playbookTemplateId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductUserForm = {
  productUserId?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  supermoveProductId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProjectAndJobTypesForProjectForm = {
  isBillingReset?: InputMaybe<Scalars['Boolean']['input']>;
  isBillingValuesReset?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  updateJobTypeForJobForms?: InputMaybe<Array<InputMaybe<UpdateJobTypeForJobForm>>>;
};

export type UpdateJobTypeForJobForm = {
  isBillingReset?: InputMaybe<Scalars['Boolean']['input']>;
  isBillingValuesReset?: InputMaybe<Scalars['Boolean']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectClientsForm = {
  billingClientForm?: InputMaybe<ClientForm>;
  clientForm?: InputMaybe<ClientForm>;
  isSameBillingClient?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectDatesForm = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobUuid?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  scheduledDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ProjectFollowUpDateForm = {
  followUpDate?: InputMaybe<Scalars['Date']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectInformationForm = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectInternalInformationForm = {
  additionalSalespersonIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  bookedById?: InputMaybe<Scalars['Int']['input']>;
  coordinatedById?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectJobsForm = {
  jobForms?: InputMaybe<Array<InputMaybe<JobForm>>>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectTipsForm = {
  jobTipsForms?: InputMaybe<Array<InputMaybe<JobTipsForm>>>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobTipsForm = {
  billTipForm?: InputMaybe<BillTipForm>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  jobUserForms?: InputMaybe<Array<InputMaybe<JobUserForm>>>;
};

export type ProjectStatusForm = {
  projectId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectTypeBillingSettingsForm = {
  crewPaymentMethods?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  defaultInvoiceDocumentTemplateId?: InputMaybe<Scalars['Int']['input']>;
  defaultInvoiceEmailTemplateId?: InputMaybe<Scalars['Int']['input']>;
  defaultInvoicePaymentTerm?: InputMaybe<Scalars['String']['input']>;
  defaultInvoiceReceiptEmailTemplateId?: InputMaybe<Scalars['Int']['input']>;
  isEnabledAutomaticallyExportInvoiceToCodat?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabledAutomaticallyFinalizeInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  shouldAttachDocumentsInInvoiceEmail?: InputMaybe<Scalars['Boolean']['input']>;
  shouldAttachDocumentsInReceiptEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectTypeDayForm = {
  isSalesLocked?: InputMaybe<Scalars['Boolean']['input']>;
  projectTypeDayId?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectTypeFeatureForm = {
  feature: Scalars['String']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  projectTypeId: Scalars['Int']['input'];
};

export type ProjectTypesForm = {
  projectTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ProjectTypeValuationCoverageForm = {
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  valuationCoverageDataTableId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProjectValuesForm = {
  editorId?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  updateJobValuesForms?: InputMaybe<Array<InputMaybe<UpdateJobValuesForm>>>;
  valueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
};

export type UpdateJobValuesForm = {
  jobId?: InputMaybe<Scalars['Int']['input']>;
  valueForms?: InputMaybe<Array<InputMaybe<ValueForm>>>;
};

export type RecurringInvoiceForm = {
  payengineCreditCardId?: InputMaybe<Scalars['Int']['input']>;
  recurringInvoiceId: Scalars['Int']['input'];
};

export type ReportMoveUserInput = {
  branchCode?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['Int']['input']>;
  moveId?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  positions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timePayableAdjustment?: InputMaybe<Scalars['Int']['input']>;
  timeRangeInputs?: InputMaybe<Array<InputMaybe<TimeRangeInput>>>;
  tip?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesTaxForm = {
  projectId?: InputMaybe<Scalars['Int']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  salesTaxId?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesTaxTemplateForm = {
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  salesTaxTemplateId?: InputMaybe<Scalars['Int']['input']>;
  settingsId?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type ShipmentStatusForm = {
  shipmentId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type TaskDueAtForm = {
  dueAtDate?: InputMaybe<Scalars['Date']['input']>;
  dueAtTime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['Int']['input']>;
};

export type ToggleBooleanPropertyForm = {
  booleanValue?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTimesheetBillableEntryForm = {
  timesheetBillableEntryId: Scalars['Int']['input'];
  timesheetBlocksCreate?: InputMaybe<Array<CreateTimesheetBlockForm>>;
  timesheetBlocksDelete?: InputMaybe<Array<DeleteTimesheetBlockForm>>;
  timesheetBlocksUpdate?: InputMaybe<Array<UpdateTimesheetBlockForm>>;
};

export type DeleteTimesheetBlockForm = {
  timesheetBlockId: Scalars['Int']['input'];
};

export type UpdateTimesheetBlockForm = {
  kind?: InputMaybe<Scalars['String']['input']>;
  rangeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  rangeTo?: InputMaybe<Scalars['DateTime']['input']>;
  timesheetBlockId: Scalars['Int']['input'];
};

export type UpdateTimesheetPayrollEntryForm = {
  adjustmentAmountSeconds?: InputMaybe<Scalars['Int']['input']>;
  adjustmentReason?: InputMaybe<Scalars['String']['input']>;
  timesheetBlocksCreate?: InputMaybe<Array<CreateTimesheetBlockForm>>;
  timesheetBlocksDelete?: InputMaybe<Array<DeleteTimesheetBlockForm>>;
  timesheetBlocksUpdate?: InputMaybe<Array<UpdateTimesheetBlockForm>>;
  timesheetPayrollEntryId: Scalars['Int']['input'];
};

export type TripStatusForm = {
  status?: InputMaybe<Scalars['String']['input']>;
  tripId?: InputMaybe<Scalars['Int']['input']>;
};

export type UserNotificationSettingForm = {
  isEmailEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isInAppEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSmsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  userNotificationSettingId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserStatusForm = {
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type BulkContainersForm = {
  data?: InputMaybe<Scalars['Upload']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type BulkStorageCustomersFormV1 = {
  data?: InputMaybe<Scalars['Upload']['input']>;
};

export type BulkStorageCustomersForm = {
  data?: InputMaybe<Scalars['Upload']['input']>;
};

export type CapacityCalendarSettingForm = {
  amCrewCapacity?: InputMaybe<Scalars['Int']['input']>;
  amTruckCapacity?: InputMaybe<Scalars['Int']['input']>;
  branchToShowIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  capacityCalendarSettingId?: InputMaybe<Scalars['Int']['input']>;
  capacityCalendarSlotMetricForms?: InputMaybe<Array<InputMaybe<CapacityCalendarSlotMetricForm>>>;
  crewForPositionCapacity?: InputMaybe<Scalars['Int']['input']>;
  moverPositionId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  pmCrewCapacity?: InputMaybe<Scalars['Int']['input']>;
  pmTruckCapacity?: InputMaybe<Scalars['Int']['input']>;
  secondaryMoverPositionId?: InputMaybe<Scalars['Int']['input']>;
  totalCrewCapacity?: InputMaybe<Scalars['Int']['input']>;
  totalCrewHoursCapacity?: InputMaybe<Scalars['Int']['input']>;
  totalTruckCapacity?: InputMaybe<Scalars['Int']['input']>;
};

export type CapacityCalendarSlotMetricForm = {
  displayKind?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  metric?: InputMaybe<Scalars['String']['input']>;
  slotPosition?: InputMaybe<Scalars['String']['input']>;
  slotPriorityIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoriesForm = {
  categoryForms?: InputMaybe<Array<InputMaybe<CategoryForm>>>;
};

export type CategoryForm = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isShared?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  validationIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type EmailContractForm = {
  attachmentContractForms?: InputMaybe<Array<InputMaybe<AttachmentContractForm>>>;
  bccEmailRecipientContractForms?: InputMaybe<Array<InputMaybe<EmailRecipientContractForm>>>;
  categoryContractForms?: InputMaybe<Array<InputMaybe<EmailCategoryContractForm>>>;
  ccEmailRecipientContractForms?: InputMaybe<Array<InputMaybe<EmailRecipientContractForm>>>;
  fromEmailRecipientContractForm?: InputMaybe<EmailRecipientContractForm>;
  headerContractForms?: InputMaybe<Array<InputMaybe<EmailHeaderContractForm>>>;
  html?: InputMaybe<Scalars['String']['input']>;
  inReplyToMessageId?: InputMaybe<Scalars['String']['input']>;
  labelContractForms?: InputMaybe<Array<InputMaybe<EmailLabelContractForm>>>;
  messageId?: InputMaybe<Scalars['String']['input']>;
  sentAt?: InputMaybe<Scalars['String']['input']>;
  sourceContractForm?: InputMaybe<SourceContractForm>;
  status?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  toEmailRecipientContractForms?: InputMaybe<Array<InputMaybe<EmailRecipientContractForm>>>;
};

export type AttachmentContractForm = {
  content?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EmailRecipientContractForm = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EmailCategoryContractForm = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EmailHeaderContractForm = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EmailLabelContractForm = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SourceContractForm = {
  kind?: InputMaybe<Scalars['String']['input']>;
};

export type ItemTagsForm = {
  itemTagForms?: InputMaybe<Array<InputMaybe<ItemTagForm>>>;
};

export type ItemTagForm = {
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  itemTagId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  validationIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type ItemTypesForm = {
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  itemTypeForms?: InputMaybe<Array<InputMaybe<ItemTypeForm>>>;
};

export type ItemTypeForm = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDerivedWeight?: InputMaybe<Scalars['Boolean']['input']>;
  itemTypeId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  validationIndex?: InputMaybe<Scalars['Int']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type JobTypeFeatureForm = {
  createdById?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  jobTypeFeatureId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type JobTypeStepForm = {
  createdById?: InputMaybe<Scalars['Int']['input']>;
  documentTemplateKinds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeStepId?: InputMaybe<Scalars['Int']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  updatedById?: InputMaybe<Scalars['Int']['input']>;
};

export type JobTypeVariableForm = {
  defaultAmount?: InputMaybe<Scalars['Float']['input']>;
  defaultStringValue?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRequiredForCreateProject?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleForCreateProject?: InputMaybe<Scalars['Boolean']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeVariableId?: InputMaybe<Scalars['Int']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type JobTypeVariableSectionsForm = {
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeVariableSectionForms?: InputMaybe<Array<InputMaybe<JobTypeVariableSectionForm>>>;
};

export type JobTypeVariableSectionForm = {
  index?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  jobTypeId?: InputMaybe<Scalars['Int']['input']>;
  jobTypeVariableForms?: InputMaybe<Array<InputMaybe<JobTypeVariableForm>>>;
  jobTypeVariableSectionId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderedVariableIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type LeadForm = {
  callId?: InputMaybe<Scalars['Int']['input']>;
  clientForm?: InputMaybe<ClientForm>;
  clientId?: InputMaybe<Scalars['Int']['input']>;
  leadId?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  locationForms?: InputMaybe<Array<InputMaybe<LocationForm>>>;
  moveSize?: InputMaybe<Scalars['String']['input']>;
  officeNotes?: InputMaybe<Scalars['String']['input']>;
  preferredDate?: InputMaybe<Scalars['String']['input']>;
  preferredTime?: InputMaybe<Scalars['String']['input']>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type LeadExportConfigurationForm = {
  customIntegrationHeaders?: InputMaybe<Scalars['JSONString']['input']>;
  customIntegrationUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  kind: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
  smartmovingBranchId?: InputMaybe<Scalars['String']['input']>;
  smartmovingProviderKey?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  supermoveJobTypeId?: InputMaybe<Scalars['Int']['input']>;
  supermoveProjectAndJobTypeAssignmentKind?: InputMaybe<Scalars['String']['input']>;
  supermoveProjectTypeId?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectContractForm = {
  clientContractForm?: InputMaybe<ClientContractForm>;
  commentContractForms?: InputMaybe<Array<InputMaybe<CommentContractForm>>>;
  creatorContractForm?: InputMaybe<UserContractForm>;
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  jobContractForms?: InputMaybe<Array<InputMaybe<JobContractForm>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeUserContractForms?: InputMaybe<Array<InputMaybe<UserContractForm>>>;
  organizationContractForm?: InputMaybe<OrganizationContractForm>;
  projectTypeContractForm?: InputMaybe<ProjectTypeContractForm>;
  referralDetails?: InputMaybe<Scalars['String']['input']>;
  referralSource?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  sourceContractForm?: InputMaybe<SourceContractForm>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  valueContractForms?: InputMaybe<Array<InputMaybe<ValueContractForm>>>;
};

export type ClientContractForm = {
  contactUserContractForms?: InputMaybe<Array<InputMaybe<UserContractForm>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserContractForm = {
  email?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  isPrimaryContact?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type CommentContractForm = {
  authorContractForm?: InputMaybe<UserContractForm>;
  body?: InputMaybe<Scalars['String']['input']>;
  categoryContractForm?: InputMaybe<CommentCategoryContractForm>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CommentCategoryContractForm = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type JobContractForm = {
  date?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  jobTypeContractForm?: InputMaybe<JobTypeContractForm>;
  locationContractForms?: InputMaybe<Array<InputMaybe<LocationContractForm>>>;
  noteContractForms?: InputMaybe<Array<InputMaybe<NoteContractForm>>>;
  numberOfMovers?: InputMaybe<Scalars['Int']['input']>;
  numberOfTrucks?: InputMaybe<Scalars['Int']['input']>;
  startTime1?: InputMaybe<Scalars['String']['input']>;
  startTime2?: InputMaybe<Scalars['String']['input']>;
  valueContractForms?: InputMaybe<Array<InputMaybe<ValueContractForm>>>;
};

export type JobTypeContractForm = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LocationContractForm = {
  address?: InputMaybe<Scalars['String']['input']>;
  floorNumber?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type NoteContractForm = {
  authorContractForm?: InputMaybe<UserContractForm>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ValueContractForm = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  stringValue?: InputMaybe<Scalars['String']['input']>;
  variableFormat?: InputMaybe<Scalars['String']['input']>;
  variableIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationContractForm = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectTypeContractForm = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectTypeVariableSectionsForm = {
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeVariableSectionForms?: InputMaybe<Array<InputMaybe<ProjectTypeVariableSectionForm>>>;
};

export type ProjectTypeVariableSectionForm = {
  index?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderedVariableIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  projectTypeId?: InputMaybe<Scalars['Int']['input']>;
  projectTypeVariableForms?: InputMaybe<Array<InputMaybe<ProjectTypeVariableForm>>>;
  projectTypeVariableSectionId?: InputMaybe<Scalars['Int']['input']>;
};

export type RoomTypesForm = {
  roomTypeForms?: InputMaybe<Array<InputMaybe<RoomTypeForm>>>;
};

export type RoomTypeForm = {
  inventoryLibraryId?: InputMaybe<Scalars['Int']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  primaryCategoryId?: InputMaybe<Scalars['Int']['input']>;
  roomTypeId?: InputMaybe<Scalars['Int']['input']>;
  validationIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type GlobalDashboardDetailsPageQueryVariables = Exact<{
  globalDashboardUuid: Scalars['String']['input'];
}>;

export type GlobalDashboardDetailsPageQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    viewingOrganization?: {
      __typename?: 'Organization';
      id: string;
      exploUserGroupToken?: string | null;
    } | null;
  } | null;
  globalDashboard?: {
    __typename?: 'GlobalDashboard';
    id: string;
    uuid: string;
    name: string;
    exploDashboardEmbedId: string;
    exploVariables?: any | null;
    status: string;
    description?: string | null;
    isDefault: boolean;
    helpArticleUrl?: string | null;
    companiesCount?: number | null;
    publishedAt?: any | null;
    updatedAt?: any | null;
    dashboardCategory?: {__typename?: 'DashboardCategory'; id: string; name: string} | null;
    dashboardTags?: Array<{
      __typename?: 'DashboardTag';
      tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
    } | null> | null;
    publishedBy?: {__typename?: 'User'; id: string; fullName?: string | null} | null;
    updatedBy?: {__typename?: 'User'; id: string; fullName?: string | null} | null;
  } | null;
};

export type ExploDashboardPanelQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExploDashboardPanelQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    viewingOrganization?: {__typename?: 'Organization'; id: string; slug?: string | null} | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    name?: string | null;
    slug?: string | null;
    exploUserGroupToken?: string | null;
  } | null;
};

export type GlobalDashboardDetailsPageAboutPanelFragment = {
  __typename?: 'GlobalDashboard';
  id: string;
  uuid: string;
  companiesCount?: number | null;
  publishedAt?: any | null;
  updatedAt?: any | null;
  publishedBy?: {__typename?: 'User'; id: string; fullName?: string | null} | null;
  updatedBy?: {__typename?: 'User'; id: string; fullName?: string | null} | null;
};

export type GlobalDashboardDetailsPageSettingsPanelFragment = {
  __typename?: 'GlobalDashboard';
  id: string;
  name: string;
  status: string;
  description?: string | null;
  isDefault: boolean;
  helpArticleUrl?: string | null;
  exploVariables?: any | null;
  exploDashboardEmbedId: string;
  dashboardCategory?: {__typename?: 'DashboardCategory'; id: string; name: string} | null;
  dashboardTags?: Array<{
    __typename?: 'DashboardTag';
    tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
  } | null> | null;
};

export type ManageGlobalDashboardCompaniesDrawerQueryVariables = Exact<{
  globalDashboardUuid: Scalars['String']['input'];
}>;

export type ManageGlobalDashboardCompaniesDrawerQuery = {
  __typename?: 'Query';
  globalDashboard?: {
    __typename?: 'GlobalDashboard';
    id: string;
    dashboards?: Array<{
      __typename?: 'Dashboard';
      id: string;
      uuid?: string | null;
      organizationId: number;
    } | null> | null;
  } | null;
  activeOrganizations?: Array<{
    __typename?: 'Organization';
    id: string;
    name?: string | null;
    company?: {
      __typename?: 'Company';
      id: string;
      primaryOrganization?: {__typename?: 'Organization'; id: string; name?: string | null} | null;
    } | null;
  } | null> | null;
};

export type ManageGlobalDashboardCompaniesDrawerMutationMutationVariables = Exact<{
  updateOrganizationsForGlobalDashboardForm: UpdateOrganizationsForGlobalDashboardForm;
}>;

export type ManageGlobalDashboardCompaniesDrawerMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateOrganizationsForGlobalDashboardMutation';
    globalDashboard?: {__typename?: 'GlobalDashboard'; id: string} | null;
  } | null;
};

export type ManageGlobalDashboardCompaniesDrawerListFragmentFragment = {
  __typename?: 'Organization';
  id: string;
  name?: string | null;
  company?: {
    __typename?: 'Company';
    id: string;
    primaryOrganization?: {__typename?: 'Organization'; id: string; name?: string | null} | null;
  } | null;
};

export type OrganizationItemFragment = {
  __typename?: 'Organization';
  id: string;
  name?: string | null;
  slug?: string | null;
  exploUserGroupToken?: string | null;
};

export type SelectOrganizationModalQueryVariables = Exact<{[key: string]: never}>;

export type SelectOrganizationModalQuery = {
  __typename?: 'Query';
  activeOrganizations?: Array<{
    __typename?: 'Organization';
    id: string;
    name?: string | null;
    slug?: string | null;
    exploUserGroupToken?: string | null;
  } | null> | null;
};

export type GlobalDashboardsPageQueryVariables = Exact<{
  sort: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  onlyDefault?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GlobalDashboardsPageQuery = {
  __typename?: 'Query';
  publishedGlobalDashboardsCount?: number | null;
  draftGlobalDashboardsCount?: number | null;
  archivedGlobalDashboardsCount?: number | null;
  viewer?: {__typename?: 'User'; id: string} | null;
  filteredGlobalDashboards?: Array<{
    __typename?: 'GlobalDashboard';
    id: string;
    uuid: string;
    name: string;
    description?: string | null;
    isDefault: boolean;
    status: string;
    companiesCount?: number | null;
    dashboardCategory?: {__typename?: 'DashboardCategory'; id: string; name: string} | null;
  } | null> | null;
};

export type CreateGlobalDashboardDrawerQueryVariables = Exact<{[key: string]: never}>;

export type CreateGlobalDashboardDrawerQuery = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null> | null;
  dashboardTags?: Array<{
    __typename?: 'Tag';
    id: string;
    name?: string | null;
    isDeleted?: boolean | null;
  } | null> | null;
};

export type GlobalDashboardCardFragment = {
  __typename?: 'GlobalDashboard';
  id: string;
  uuid: string;
  name: string;
  description?: string | null;
  isDefault: boolean;
  status: string;
  companiesCount?: number | null;
  dashboardCategory?: {__typename?: 'DashboardCategory'; id: string; name: string} | null;
};

export type GlobalDashboardFieldsFragment = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null> | null;
  dashboardTags?: Array<{
    __typename?: 'Tag';
    id: string;
    name?: string | null;
    isDeleted?: boolean | null;
  } | null> | null;
};

export type GlobalDashboardFilterQueryVariables = Exact<{[key: string]: never}>;

export type GlobalDashboardFilterQuery = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
  } | null> | null;
  dashboardTags?: Array<{__typename?: 'Tag'; id: string; name?: string | null} | null> | null;
};

export type ManageCategoriesDrawerQueryVariables = Exact<{[key: string]: never}>;

export type ManageCategoriesDrawerQuery = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null> | null;
};

export type ManageDashboardCategoriesListFragment = {
  __typename?: 'DashboardCategory';
  id: string;
  name: string;
  index: number;
  isDeleted: boolean;
};

export type ManageDashboardCategoryDeleteModalQueryVariables = Exact<{
  dashboardCategoryId: Scalars['Int']['input'];
}>;

export type ManageDashboardCategoryDeleteModalQuery = {
  __typename?: 'Query';
  dashboardCategory?: {
    __typename?: 'DashboardCategory';
    id: string;
    globalDashboardsAndDashboardsCount?: number | null;
  } | null;
};

export type ManageDashboardCategoryDeleteModalCategoryFragment = {
  __typename?: 'DashboardCategory';
  id: string;
  name: string;
};

export type ManageDashboardTagsDrawerQueryVariables = Exact<{[key: string]: never}>;

export type ManageDashboardTagsDrawerQuery = {
  __typename?: 'Query';
  dashboardTags?: Array<{
    __typename?: 'Tag';
    id: string;
    name?: string | null;
    isDeleted?: boolean | null;
  } | null> | null;
};

export type UpdateGlobalDashboardDrawerQueryVariables = Exact<{
  globalDashboardUuid: Scalars['String']['input'];
}>;

export type UpdateGlobalDashboardDrawerQuery = {
  __typename?: 'Query';
  globalDashboard?: {
    __typename?: 'GlobalDashboard';
    id: string;
    name: string;
    description?: string | null;
    helpArticleUrl?: string | null;
    isDefault: boolean;
    exploDashboardEmbedId: string;
    exploVariables?: any | null;
    dashboardCategory?: {
      __typename?: 'DashboardCategory';
      id: string;
      name: string;
      index: number;
      isDeleted: boolean;
    } | null;
    dashboardTags?: Array<{
      __typename?: 'DashboardTag';
      id: string;
      tag?: {
        __typename?: 'Tag';
        id: string;
        name?: string | null;
        isDeleted?: boolean | null;
      } | null;
    } | null> | null;
  } | null;
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null> | null;
  dashboardTags?: Array<{
    __typename?: 'Tag';
    id: string;
    name?: string | null;
    isDeleted?: boolean | null;
  } | null> | null;
};

export type ShowDashboardPageQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type ShowDashboardPageQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    viewingOrganization?: {
      __typename?: 'Organization';
      id: string;
      features?: {
        __typename?: 'OrganizationFeatures';
        isEnabledReportsSaveCopy?: boolean | null;
      } | null;
    } | null;
  } | null;
  dashboard?: {
    __typename?: 'Dashboard';
    id: string;
    name?: string | null;
    exploDashboardEmbedId?: string | null;
    exploVariables?: string | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      exploUserGroupToken?: string | null;
    } | null;
  } | null;
};

export type DashboardsPageQueryVariables = Exact<{[key: string]: never}>;

export type DashboardsPageQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    viewingOrganization?: {
      __typename?: 'Organization';
      id: string;
      features?: {
        __typename?: 'OrganizationFeatures';
        isEnabledOfficeReportsLibrary?: boolean | null;
      } | null;
      settings?: {__typename?: 'Settings'; id: string; isStorageEnabled?: boolean | null} | null;
    } | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    dashboards?: Array<{
      __typename?: 'Dashboard';
      id: string;
      uuid?: string | null;
      name?: string | null;
      dashboardCategoryId?: number | null;
      globalDashboard?: {
        __typename?: 'GlobalDashboard';
        id: string;
        dashboardCategoryId: number;
      } | null;
    } | null> | null;
  } | null;
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
  } | null> | null;
};

export type CreateDashboardFromGlobalDashboardMutationMutationVariables = Exact<{
  dashboardFromGlobalDashboardForm: DashboardFromGlobalDashboardForm;
}>;

export type CreateDashboardFromGlobalDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'CreateDashboardFromGlobalDashboardMutation';
    dashboard?: {
      __typename?: 'Dashboard';
      id: string;
      uuid?: string | null;
      name?: string | null;
    } | null;
  } | null;
};

export type GlobalDashboardsLibraryPageQueryQueryVariables = Exact<{
  sort: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  onlyDefault?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GlobalDashboardsLibraryPageQueryQuery = {
  __typename?: 'Query';
  filteredGlobalDashboards?: Array<{
    __typename?: 'GlobalDashboard';
    id: string;
    uuid: string;
    name: string;
    description?: string | null;
    publishedAt?: any | null;
    dashboardCategory?: {__typename?: 'DashboardCategory'; id: string; name: string} | null;
  } | null> | null;
  viewer?: {
    __typename?: 'User';
    id: string;
    viewingOrganization?: {
      __typename?: 'Organization';
      id: string;
      features?: {
        __typename?: 'OrganizationFeatures';
        isEnabledOfficeReportsLibrary?: boolean | null;
      } | null;
      settings?: {__typename?: 'Settings'; id: string; isStorageEnabled?: boolean | null} | null;
    } | null;
  } | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    dashboards?: Array<{
      __typename?: 'Dashboard';
      id: string;
      uuid?: string | null;
      globalDashboard?: {__typename?: 'GlobalDashboard'; id: string; uuid: string} | null;
    } | null> | null;
  } | null;
};

export type GlobalDashboardsLibraryPageCreateDashboardDrawerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GlobalDashboardsLibraryPageCreateDashboardDrawerQuery = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null> | null;
  dashboardTags?: Array<{
    __typename?: 'Tag';
    id: string;
    name?: string | null;
    isDeleted?: boolean | null;
  } | null> | null;
};

export type GlobalDashboardsLibraryPageDashboardFieldsFragment = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null> | null;
  dashboardTags?: Array<{
    __typename?: 'Tag';
    id: string;
    name?: string | null;
    isDeleted?: boolean | null;
  } | null> | null;
};

export type GlobalDashboardsLibraryPageGlobalDashboardViewPanelQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GlobalDashboardsLibraryPageGlobalDashboardViewPanelQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    viewingOrganization?: {
      __typename?: 'Organization';
      id: string;
      exploUserGroupToken?: string | null;
    } | null;
  } | null;
  globalDashboard?: {
    __typename?: 'GlobalDashboard';
    id: string;
    name: string;
    exploDashboardEmbedId: string;
    exploVariables?: any | null;
    description?: string | null;
    helpArticleUrl?: string | null;
    dashboardCategory?: {__typename?: 'DashboardCategory'; id: string; name: string} | null;
    dashboardTags?: Array<{
      __typename?: 'DashboardTag';
      id: string;
      tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
    } | null> | null;
  } | null;
};

export type GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragmentFragment = {
  __typename?: 'GlobalDashboard';
  id: string;
  name: string;
  description?: string | null;
  helpArticleUrl?: string | null;
  dashboardCategory?: {__typename?: 'DashboardCategory'; id: string; name: string} | null;
  dashboardTags?: Array<{
    __typename?: 'DashboardTag';
    id: string;
    tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
  } | null> | null;
};

export type GlobalDashboardsLibraryPageGlobalDashboardsFilterQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GlobalDashboardsLibraryPageGlobalDashboardsFilterQuery = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
  } | null> | null;
  dashboardTags?: Array<{__typename?: 'Tag'; id: string; name?: string | null} | null> | null;
};

export type GlobalDashboardsLibraryPageGlobalDashboardsListItemFragment = {
  __typename?: 'GlobalDashboard';
  id: string;
  uuid: string;
  name: string;
  description?: string | null;
  publishedAt?: any | null;
};

export type GlobalDashboardsLibraryPageUpdateDashboardDrawerQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type GlobalDashboardsLibraryPageUpdateDashboardDrawerQuery = {
  __typename?: 'Query';
  dashboard?: {
    __typename?: 'Dashboard';
    id: string;
    organizationId: number;
    kind?: string | null;
    category?: string | null;
    name?: string | null;
    exploDashboardEmbedId?: string | null;
    exploVariables?: string | null;
    index?: number | null;
    uuid?: string | null;
    dashboardCategoryId?: number | null;
    globalDashboardId?: number | null;
    description?: string | null;
    helpArticleUrl?: string | null;
    dashboardTags?: Array<{
      __typename?: 'DashboardTag';
      id: string;
      tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
    } | null> | null;
  } | null;
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null> | null;
  dashboardTags?: Array<{
    __typename?: 'Tag';
    id: string;
    name?: string | null;
    isDeleted?: boolean | null;
  } | null> | null;
};

export type EditDashboardModalQueryVariables = Exact<{[key: string]: never}>;

export type EditDashboardModalQuery = {
  __typename?: 'Query';
  dashboardCategories?: Array<{
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
  } | null> | null;
};

export type DashboardsPageEditDashboardModalFragmentFragment = {
  __typename?: 'Dashboard';
  id: string;
  uuid?: string | null;
  organizationId: number;
  name?: string | null;
  description?: string | null;
  dashboardCategoryId?: number | null;
  exploVariables?: string | null;
};

export type UseDeleteDashboardMutationMutationVariables = Exact<{
  dashboardForm: DashboardForm;
}>;

export type UseDeleteDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'DeleteDashboardMutation';
    dashboard?: {__typename?: 'Dashboard'; id: string} | null;
  } | null;
};

export type DashboardsPageRemoveDashboardModalFragmentFragment = {
  __typename?: 'Dashboard';
  id: string;
  organizationId: number;
  kind?: string | null;
  category?: string | null;
  name?: string | null;
  exploDashboardEmbedId?: string | null;
  exploVariables?: string | null;
  index?: number | null;
  uuid?: string | null;
  dashboardCategoryId?: number | null;
  globalDashboardId?: number | null;
  description?: string | null;
  helpArticleUrl?: string | null;
  dashboardTags?: Array<{
    __typename?: 'DashboardTag';
    id: string;
    tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
  } | null> | null;
};

export type UseResetDashboardToGlobalDashboardMutationMutationVariables = Exact<{
  dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm;
}>;

export type UseResetDashboardToGlobalDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'ResetDashboardToGlobalDashboardMutation';
    dashboard?: {__typename?: 'Dashboard'; id: string} | null;
  } | null;
};

export type DashboardsPageUpdateDashboardVariablesModalFragment = {
  __typename?: 'Dashboard';
  id: string;
  organizationId: number;
  dashboardCategoryId?: number | null;
  name?: string | null;
  description?: string | null;
  exploVariables?: string | null;
};

export type DashboardsPageViewPanelQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;

export type DashboardsPageViewPanelQuery = {
  __typename?: 'Query';
  dashboard?: {
    __typename?: 'Dashboard';
    id: string;
    name?: string | null;
    globalDashboardId?: number | null;
    exploDashboardEmbedId?: string | null;
    exploVariables?: string | null;
    description?: string | null;
    dashboardCategoryId?: number | null;
    helpArticleUrl?: string | null;
    uuid?: string | null;
    organizationId: number;
    kind?: string | null;
    category?: string | null;
    index?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      exploUserGroupToken?: string | null;
      features?: {
        __typename?: 'OrganizationFeatures';
        isEnabledOfficeReportsUpdateAndResetView?: boolean | null;
      } | null;
    } | null;
    dashboardTags?: Array<{
      __typename?: 'DashboardTag';
      id: string;
      tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
    } | null> | null;
    globalDashboard?: {
      __typename?: 'GlobalDashboard';
      id: string;
      name: string;
      description?: string | null;
      dashboardCategoryId: number;
      helpArticleUrl?: string | null;
      exploVariables?: any | null;
    } | null;
  } | null;
};

export type DashboardsPageViewPanelHeaderFragmentFragment = {
  __typename?: 'Dashboard';
  id: string;
  name?: string | null;
  description?: string | null;
  dashboardCategoryId?: number | null;
  exploVariables?: string | null;
  helpArticleUrl?: string | null;
  dashboardTags?: Array<{
    __typename?: 'DashboardTag';
    id: string;
    tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
  } | null> | null;
  globalDashboard?: {
    __typename?: 'GlobalDashboard';
    id: string;
    name: string;
    description?: string | null;
    dashboardCategoryId: number;
    helpArticleUrl?: string | null;
    exploVariables?: any | null;
  } | null;
};

export type Dashboard_IsVariablesChangedFragment = {
  __typename?: 'Dashboard';
  id: string;
  exploVariables?: string | null;
};

export type Dashboard_IsDashboardDifferentFromGlobalDashboardFragment = {
  __typename?: 'Dashboard';
  id: string;
  dashboardCategoryId?: number | null;
  name?: string | null;
  description?: string | null;
  exploVariables?: string | null;
  globalDashboard?: {
    __typename?: 'GlobalDashboard';
    dashboardCategoryId: number;
    name: string;
    description?: string | null;
    exploVariables?: any | null;
  } | null;
};

export type DashboardCategoryFormEditFragment = {
  __typename?: 'DashboardCategory';
  id: string;
  name: string;
  index: number;
  isDeleted: boolean;
};

export type UseCreateDashboardCategoryMutationMutationVariables = Exact<{
  dashboardCategoryForm: DashboardCategoryForm;
}>;

export type UseCreateDashboardCategoryMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'CreateDashboardCategoryMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    dashboardCategory?: {__typename?: 'DashboardCategory'; id: string} | null;
  } | null;
};

export type UseDeleteDashboardCategoryMutationMutationVariables = Exact<{
  dashboardCategoryForm: DashboardCategoryForm;
}>;

export type UseDeleteDashboardCategoryMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'DeleteDashboardCategoryMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    dashboardCategory?: {__typename?: 'DashboardCategory'; id: string} | null;
  } | null;
};

export type UseReorderDashboardCategoriesMutationMutationVariables = Exact<{
  reorderDashboardCategoriesForm: ReorderDashboardCategoriesForm;
}>;

export type UseReorderDashboardCategoriesMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'ReorderDashboardCategoriesMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    dashboardCategories?: Array<{__typename?: 'DashboardCategory'; id: string} | null> | null;
  } | null;
};

export type UseUpdateDashboardCategoryMutationMutationVariables = Exact<{
  dashboardCategoryForm: DashboardCategoryForm;
}>;

export type UseUpdateDashboardCategoryMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateDashboardCategoryMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    dashboardCategory?: {__typename?: 'DashboardCategory'; id: string} | null;
  } | null;
};

export type DashboardTagFormEditFragment = {
  __typename?: 'Tag';
  id: string;
  name?: string | null;
  isDeleted?: boolean | null;
};

export type UseCreateDashboardTagMutationMutationVariables = Exact<{
  dashboardTagForm: DashboardTagForm;
}>;

export type UseCreateDashboardTagMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'CreateDashboardTagMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    dashboardTag?: {__typename?: 'Tag'; id: string} | null;
  } | null;
};

export type UseDeleteDashboardTagMutationMutationVariables = Exact<{
  dashboardTagForm: DashboardTagForm;
}>;

export type UseDeleteDashboardTagMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'DeleteDashboardTagMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    dashboardTag?: {__typename?: 'Tag'; id: string} | null;
  } | null;
};

export type UseUpdateDashboardTagMutationMutationVariables = Exact<{
  dashboardTagForm: DashboardTagForm;
}>;

export type UseUpdateDashboardTagMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateDashboardTagMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    dashboardTag?: {__typename?: 'Tag'; id: string} | null;
  } | null;
};

export type GlobalDashboardFormEditFragment = {
  __typename?: 'GlobalDashboard';
  id: string;
  name: string;
  description?: string | null;
  helpArticleUrl?: string | null;
  isDefault: boolean;
  exploDashboardEmbedId: string;
  exploVariables?: any | null;
  dashboardCategory?: {
    __typename?: 'DashboardCategory';
    id: string;
    name: string;
    index: number;
    isDeleted: boolean;
  } | null;
  dashboardTags?: Array<{
    __typename?: 'DashboardTag';
    id: string;
    tag?: {__typename?: 'Tag'; id: string; name?: string | null; isDeleted?: boolean | null} | null;
  } | null> | null;
};

export type UseCreateGlobalDashboardMutationMutationVariables = Exact<{
  globalDashboardForm: GlobalDashboardForm;
}>;

export type UseCreateGlobalDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'CreateGlobalDashboardMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    globalDashboard?: {
      __typename?: 'GlobalDashboard';
      id: string;
      uuid: string;
      name: string;
    } | null;
  } | null;
};

export type UseDuplicateGlobalDashboardMutationMutationVariables = Exact<{
  duplicateGlobalDashboardForm: DuplicateGlobalDashboardForm;
}>;

export type UseDuplicateGlobalDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'DuplicateGlobalDashboardMutation';
    globalDashboard?: {
      __typename?: 'GlobalDashboard';
      id: string;
      uuid: string;
      name: string;
    } | null;
  } | null;
};

export type UseUpdateGlobalDashboardMutationMutationVariables = Exact<{
  globalDashboardForm: GlobalDashboardForm;
}>;

export type UseUpdateGlobalDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateGlobalDashboardMutation';
    errors?: Array<{
      __typename?: 'Type';
      field?: string | null;
      message?: string | null;
    } | null> | null;
    globalDashboard?: {
      __typename?: 'GlobalDashboard';
      id: string;
      uuid: string;
      name: string;
    } | null;
  } | null;
};

export type UseUpdateGlobalDashboardStatusMutationMutationVariables = Exact<{
  updateGlobalDashboardStatusForm: UpdateGlobalDashboardStatusForm;
}>;

export type UseUpdateGlobalDashboardStatusMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateGlobalDashboardStatusMutation';
    globalDashboard?: {
      __typename?: 'GlobalDashboard';
      id: string;
      uuid: string;
      name: string;
    } | null;
  } | null;
};

export type DashboardFormEditFragment = {
  __typename?: 'Dashboard';
  id: string;
  organizationId: number;
  kind?: string | null;
  category?: string | null;
  name?: string | null;
  exploDashboardEmbedId?: string | null;
  exploVariables?: string | null;
  index?: number | null;
  uuid?: string | null;
  dashboardCategoryId?: number | null;
  globalDashboardId?: number | null;
  description?: string | null;
  helpArticleUrl?: string | null;
  dashboardTags?: Array<{
    __typename?: 'DashboardTag';
    id: string;
    tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
  } | null> | null;
};

export type DashboardLinkedToGlobalDashboardFormEditFragment = {
  __typename?: 'Dashboard';
  id: string;
  organizationId: number;
  dashboardCategoryId?: number | null;
  name?: string | null;
  description?: string | null;
  exploVariables?: string | null;
};

export type ReorderReportDashboardsForm_EditFragment = {
  __typename?: 'Dashboard';
  id: string;
  organizationId: number;
  kind?: string | null;
  category?: string | null;
  name?: string | null;
  exploDashboardEmbedId?: string | null;
  exploVariables?: string | null;
  index?: number | null;
  uuid?: string | null;
  dashboardCategoryId?: number | null;
  globalDashboardId?: number | null;
  description?: string | null;
  helpArticleUrl?: string | null;
  dashboardTags?: Array<{
    __typename?: 'DashboardTag';
    id: string;
    tag?: {__typename?: 'Tag'; id: string; name?: string | null} | null;
  } | null> | null;
};

export type UseCreateDashboardMutationMutationVariables = Exact<{
  dashboardForm: DashboardForm;
}>;

export type UseCreateDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'CreateDashboardMutation';
    dashboard?: {
      __typename?: 'Dashboard';
      id: string;
      name?: string | null;
      uuid?: string | null;
    } | null;
  } | null;
};

export type UseCreateReportDashboardMutationMutationVariables = Exact<{
  reportDashboardForm: DashboardForm;
}>;

export type UseCreateReportDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'CreateReportDashboardMutation';
    dashboard?: {
      __typename?: 'Dashboard';
      id: string;
      name?: string | null;
      uuid?: string | null;
    } | null;
  } | null;
};

export type UseDeleteReportDashboardMutationMutationVariables = Exact<{
  reportDashboardForm: DashboardForm;
}>;

export type UseDeleteReportDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'DeleteReportDashboardMutation';
    dashboard?: {__typename?: 'Dashboard'; id: string} | null;
  } | null;
};

export type UseReorderReportDashboardsMutationMutationVariables = Exact<{
  reorderReportDashboardsForm: ReorderReportDashboardsForm;
}>;

export type UseReorderReportDashboardsMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'ReorderReportDashboardsMutation';
    dashboards?: Array<{__typename?: 'Dashboard'; id: string} | null> | null;
  } | null;
};

export type UseUpdateDashboardLinkedToGlobalDashboardMutationMutationVariables = Exact<{
  dashboardLinkedToGlobalDashboardForm: DashboardLinkedToGlobalDashboardForm;
}>;

export type UseUpdateDashboardLinkedToGlobalDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateDashboardLinkedToGlobalDashboardMutation';
    dashboard?: {__typename?: 'Dashboard'; id: string} | null;
  } | null;
};

export type UseUpdateDashboardMutationMutationVariables = Exact<{
  dashboardForm: DashboardForm;
}>;

export type UseUpdateDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateDashboardMutation';
    dashboard?: {
      __typename?: 'Dashboard';
      id: string;
      name?: string | null;
      uuid?: string | null;
    } | null;
  } | null;
};

export type UseUpdateReportDashboardMutationMutationVariables = Exact<{
  reportDashboardForm: DashboardForm;
}>;

export type UseUpdateReportDashboardMutationMutation = {
  __typename?: 'Mutation';
  response?: {
    __typename?: 'UpdateReportDashboardMutation';
    dashboard?: {__typename?: 'Dashboard'; id: string} | null;
  } | null;
};

export const GlobalDashboardDetailsPageAboutPanelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardDetailsPageAboutPanel'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'companiesCount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'publishedAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'publishedBy'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fullName'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatedBy'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fullName'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardDetailsPageAboutPanelFragment, unknown>;
export const GlobalDashboardDetailsPageSettingsPanelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardDetailsPageSettingsPanel'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardDetailsPageSettingsPanelFragment, unknown>;
export const ManageGlobalDashboardCompaniesDrawerListFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ManageGlobalDashboardCompaniesDrawerListFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Organization'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'company'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'primaryOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManageGlobalDashboardCompaniesDrawerListFragmentFragment, unknown>;
export const OrganizationItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'OrganizationItem'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Organization'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'slug'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploUserGroupToken'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationItemFragment, unknown>;
export const GlobalDashboardCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardCard'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'companiesCount'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardCardFragment, unknown>;
export const DashboardCategoryFormEditFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardCategoryFormEditFragment, unknown>;
export const DashboardTagFormEditFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardTagFormEditFragment, unknown>;
export const GlobalDashboardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardFields'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Query'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardFieldsFragment, unknown>;
export const ManageDashboardCategoryDeleteModalCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ManageDashboardCategoryDeleteModalCategory'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManageDashboardCategoryDeleteModalCategoryFragment, unknown>;
export const ManageDashboardCategoriesListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ManageDashboardCategoriesList'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'ManageDashboardCategoryDeleteModalCategory'},
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ManageDashboardCategoryDeleteModalCategory'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManageDashboardCategoriesListFragment, unknown>;
export const GlobalDashboardsLibraryPageDashboardFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageDashboardFields'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Query'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardsLibraryPageDashboardFieldsFragment, unknown>;
export const GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment',
      },
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragmentFragment,
  unknown
>;
export const GlobalDashboardsLibraryPageGlobalDashboardsListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageGlobalDashboardsListItem'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'publishedAt'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardsLibraryPageGlobalDashboardsListItemFragment, unknown>;
export const DashboardsPageEditDashboardModalFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageEditDashboardModalFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsPageEditDashboardModalFragmentFragment, unknown>;
export const DashboardFormEditFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'kind'}},
          {kind: 'Field', name: {kind: 'Name', value: 'category'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'globalDashboardId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardFormEditFragment, unknown>;
export const DashboardsPageRemoveDashboardModalFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageRemoveDashboardModalFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardFormEdit'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'kind'}},
          {kind: 'Field', name: {kind: 'Name', value: 'category'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'globalDashboardId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsPageRemoveDashboardModalFragmentFragment, unknown>;
export const DashboardLinkedToGlobalDashboardFormEditFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardLinkedToGlobalDashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardLinkedToGlobalDashboardFormEditFragment, unknown>;
export const DashboardsPageUpdateDashboardVariablesModalFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageUpdateDashboardVariablesModal'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'DashboardLinkedToGlobalDashboardFormEdit'},
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardLinkedToGlobalDashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsPageUpdateDashboardVariablesModalFragment, unknown>;
export const Dashboard_IsVariablesChangedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<Dashboard_IsVariablesChangedFragment, unknown>;
export const Dashboard_IsDashboardDifferentFromGlobalDashboardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'Dashboard_isDashboardDifferentFromGlobalDashboard'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
              ],
            },
          },
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<Dashboard_IsDashboardDifferentFromGlobalDashboardFragment, unknown>;
export const DashboardsPageViewPanelHeaderFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageViewPanelHeaderFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'Dashboard_isDashboardDifferentFromGlobalDashboard'},
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'Dashboard_isDashboardDifferentFromGlobalDashboard'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
              ],
            },
          },
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsPageViewPanelHeaderFragmentFragment, unknown>;
export const GlobalDashboardFormEditFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardFormEditFragment, unknown>;
export const ReorderReportDashboardsForm_EditFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ReorderReportDashboardsForm_edit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardFormEdit'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'kind'}},
          {kind: 'Field', name: {kind: 'Name', value: 'category'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'globalDashboardId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReorderReportDashboardsForm_EditFragment, unknown>;
export const GlobalDashboardDetailsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardDetailsPage'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardUuid'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'viewingOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'exploUserGroupToken'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'uuid'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardUuid'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'GlobalDashboardDetailsPageSettingsPanel'},
                },
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'GlobalDashboardDetailsPageAboutPanel'},
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardDetailsPageSettingsPanel'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardDetailsPageAboutPanel'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'companiesCount'}},
          {kind: 'Field', name: {kind: 'Name', value: 'publishedAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'publishedBy'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fullName'}},
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatedBy'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fullName'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalDashboardDetailsPageQuery,
  GlobalDashboardDetailsPageQueryVariables
>;
export const ExploDashboardPanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ExploDashboardPanel'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'slug'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'viewingOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'slug'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'organization'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'slug'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'slug'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'OrganizationItem'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'OrganizationItem'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Organization'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'slug'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploUserGroupToken'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExploDashboardPanelQuery, ExploDashboardPanelQueryVariables>;
export const ManageGlobalDashboardCompaniesDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ManageGlobalDashboardCompaniesDrawer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardUuid'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'uuid'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardUuid'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboards'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'activeOrganizations'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'ManageGlobalDashboardCompaniesDrawerListFragment'},
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ManageGlobalDashboardCompaniesDrawerListFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Organization'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'company'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'primaryOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageGlobalDashboardCompaniesDrawerQuery,
  ManageGlobalDashboardCompaniesDrawerQueryVariables
>;
export const ManageGlobalDashboardCompaniesDrawerMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'ManageGlobalDashboardCompaniesDrawerMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'updateOrganizationsForGlobalDashboardForm'},
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'UpdateOrganizationsForGlobalDashboardForm'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateOrganizationsForGlobalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'updateOrganizationsForGlobalDashboardForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'updateOrganizationsForGlobalDashboardForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'globalDashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageGlobalDashboardCompaniesDrawerMutationMutation,
  ManageGlobalDashboardCompaniesDrawerMutationMutationVariables
>;
export const SelectOrganizationModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'SelectOrganizationModal'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'activeOrganizations'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'slug'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'OrganizationItem'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'OrganizationItem'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Organization'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'slug'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploUserGroupToken'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectOrganizationModalQuery, SelectOrganizationModalQueryVariables>;
export const GlobalDashboardsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardsPage'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sort'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'categoryIds'}},
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'tagIds'}},
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'searchQuery'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'onlyDefault'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'filteredGlobalDashboards'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sort'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sort'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'categoryIds'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'categoryIds'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'tagIds'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'tagIds'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'searchQuery'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'searchQuery'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'onlyDefault'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'onlyDefault'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'GlobalDashboardCard'}},
              ],
            },
          },
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'publishedGlobalDashboardsCount'},
            name: {kind: 'Name', value: 'filteredGlobalDashboardsCount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'StringValue', value: 'PUBLISHED', block: false},
              },
            ],
          },
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'draftGlobalDashboardsCount'},
            name: {kind: 'Name', value: 'filteredGlobalDashboardsCount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'StringValue', value: 'DRAFT', block: false},
              },
            ],
          },
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'archivedGlobalDashboardsCount'},
            name: {kind: 'Name', value: 'filteredGlobalDashboardsCount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'StringValue', value: 'ARCHIVED', block: false},
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardCard'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'status'}},
          {kind: 'Field', name: {kind: 'Name', value: 'companiesCount'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardsPageQuery, GlobalDashboardsPageQueryVariables>;
export const CreateGlobalDashboardDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CreateGlobalDashboardDrawer'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'GlobalDashboardFields'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardFields'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Query'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGlobalDashboardDrawerQuery,
  CreateGlobalDashboardDrawerQueryVariables
>;
export const GlobalDashboardFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardFilter'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalDashboardFilterQuery, GlobalDashboardFilterQueryVariables>;
export const ManageCategoriesDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ManageCategoriesDrawer'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'ManageDashboardCategoriesList'},
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ManageDashboardCategoryDeleteModalCategory'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'ManageDashboardCategoriesList'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'ManageDashboardCategoryDeleteModalCategory'},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManageCategoriesDrawerQuery, ManageCategoriesDrawerQueryVariables>;
export const ManageDashboardCategoryDeleteModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ManageDashboardCategoryDeleteModal'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardCategoryId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'globalDashboardsAndDashboardsCount'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageDashboardCategoryDeleteModalQuery,
  ManageDashboardCategoryDeleteModalQueryVariables
>;
export const ManageDashboardTagsDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ManageDashboardTagsDrawer'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageDashboardTagsDrawerQuery,
  ManageDashboardTagsDrawerQueryVariables
>;
export const UpdateGlobalDashboardDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UpdateGlobalDashboardDrawer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardUuid'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'uuid'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardUuid'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'GlobalDashboardFormEdit'}},
              ],
            },
          },
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'GlobalDashboardFields'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDefault'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardFields'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Query'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGlobalDashboardDrawerQuery,
  UpdateGlobalDashboardDrawerQueryVariables
>;
export const ShowDashboardPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'ShowDashboardPage'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'viewingOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'features'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: {kind: 'Name', value: 'isEnabledReportsSaveCopy'},
                              name: {kind: 'Name', value: 'isEnabled'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'feature'},
                                  value: {
                                    kind: 'StringValue',
                                    value: 'REPORTS_SAVE_COPY',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardUuid'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'organization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'exploUserGroupToken'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShowDashboardPageQuery, ShowDashboardPageQueryVariables>;
export const DashboardsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'DashboardsPage'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'viewingOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'features'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: {kind: 'Name', value: 'isEnabledOfficeReportsLibrary'},
                              name: {kind: 'Name', value: 'isEnabled'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'feature'},
                                  value: {
                                    kind: 'StringValue',
                                    value: 'OFFICE_REPORTS_LIBRARY',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'settings'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'isStorageEnabled'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'organization'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboards'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'globalDashboard'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'index'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsPageQuery, DashboardsPageQueryVariables>;
export const CreateDashboardFromGlobalDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'CreateDashboardFromGlobalDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'dashboardFromGlobalDashboardForm'},
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'DashboardFromGlobalDashboardForm'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'createDashboardFromGlobalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardFromGlobalDashboardForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'dashboardFromGlobalDashboardForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDashboardFromGlobalDashboardMutationMutation,
  CreateDashboardFromGlobalDashboardMutationMutationVariables
>;
export const GlobalDashboardsLibraryPageQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageQuery'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'sort'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'categoryIds'}},
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'tagIds'}},
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'searchQuery'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'onlyDefault'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'filteredGlobalDashboards'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'sort'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'sort'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'categoryIds'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'categoryIds'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'tagIds'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'tagIds'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'searchQuery'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'searchQuery'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'onlyDefault'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'onlyDefault'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardCategory'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GlobalDashboardsLibraryPageGlobalDashboardsListItem',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'viewingOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'features'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: {kind: 'Name', value: 'isEnabledOfficeReportsLibrary'},
                              name: {kind: 'Name', value: 'isEnabled'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'feature'},
                                  value: {
                                    kind: 'StringValue',
                                    value: 'OFFICE_REPORTS_LIBRARY',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'settings'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'isStorageEnabled'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'organization'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboards'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'globalDashboard'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageGlobalDashboardsListItem'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'publishedAt'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalDashboardsLibraryPageQueryQuery,
  GlobalDashboardsLibraryPageQueryQueryVariables
>;
export const GlobalDashboardsLibraryPageCreateDashboardDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageCreateDashboardDrawer'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageDashboardFields'},
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageDashboardFields'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Query'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalDashboardsLibraryPageCreateDashboardDrawerQuery,
  GlobalDashboardsLibraryPageCreateDashboardDrawerQueryVariables
>;
export const GlobalDashboardsLibraryPageGlobalDashboardViewPanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageGlobalDashboardViewPanel'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'viewingOrganization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'exploUserGroupToken'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'uuid'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'GlobalDashboardsLibraryPageGlobalDashboardViewPanelHeaderFragment',
      },
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategory'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalDashboardsLibraryPageGlobalDashboardViewPanelQuery,
  GlobalDashboardsLibraryPageGlobalDashboardViewPanelQueryVariables
>;
export const GlobalDashboardsLibraryPageGlobalDashboardsFilterDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageGlobalDashboardsFilter'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalDashboardsLibraryPageGlobalDashboardsFilterQuery,
  GlobalDashboardsLibraryPageGlobalDashboardsFilterQueryVariables
>;
export const GlobalDashboardsLibraryPageUpdateDashboardDrawerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageUpdateDashboardDrawer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardUuid'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardFormEdit'}},
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageDashboardFields'},
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardCategoryFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategory'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardTagFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Tag'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'isDeleted'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'kind'}},
          {kind: 'Field', name: {kind: 'Name', value: 'category'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'globalDashboardId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'GlobalDashboardsLibraryPageDashboardFields'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Query'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardCategoryFormEdit'}},
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardTagFormEdit'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalDashboardsLibraryPageUpdateDashboardDrawerQuery,
  GlobalDashboardsLibraryPageUpdateDashboardDrawerQueryVariables
>;
export const EditDashboardModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'EditDashboardModal'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardCategories'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditDashboardModalQuery, EditDashboardModalQueryVariables>;
export const UseDeleteDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useDeleteDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'deleteDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDeleteDashboardMutationMutation,
  UseDeleteDashboardMutationMutationVariables
>;
export const UseResetDashboardToGlobalDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useResetDashboardToGlobalDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'dashboardLinkedToGlobalDashboardForm'},
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'DashboardLinkedToGlobalDashboardForm'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'resetDashboardToGlobalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardLinkedToGlobalDashboardForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'dashboardLinkedToGlobalDashboardForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseResetDashboardToGlobalDashboardMutationMutation,
  UseResetDashboardToGlobalDashboardMutationMutationVariables
>;
export const DashboardsPageViewPanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'DashboardsPageViewPanel'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardUuid'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'uuid'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'globalDashboardId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'organization'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'exploUserGroupToken'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'features'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: {
                                kind: 'Name',
                                value: 'isEnabledOfficeReportsUpdateAndResetView',
                              },
                              name: {kind: 'Name', value: 'isEnabled'},
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {kind: 'Name', value: 'feature'},
                                  value: {
                                    kind: 'StringValue',
                                    value: 'OFFICE_REPORTS_UPDATE_AND_RESET_VIEW',
                                    block: false,
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'DashboardsPageViewPanelHeaderFragment'},
                },
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'DashboardsPageEditDashboardModalFragment'},
                },
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'DashboardsPageRemoveDashboardModalFragment'},
                },
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'DashboardsPageUpdateDashboardVariablesModal'},
                },
                {
                  kind: 'FragmentSpread',
                  name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'},
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'Dashboard_isDashboardDifferentFromGlobalDashboard'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
              ],
            },
          },
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'Dashboard_isVariablesChanged'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'kind'}},
          {kind: 'Field', name: {kind: 'Name', value: 'category'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploDashboardEmbedId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'index'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'globalDashboardId'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardLinkedToGlobalDashboardFormEdit'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageViewPanelHeaderFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
          {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'dashboardTags'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'tag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'globalDashboard'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'description'}},
                {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'helpArticleUrl'}},
                {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'Dashboard_isDashboardDifferentFromGlobalDashboard'},
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageEditDashboardModalFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
          {kind: 'Field', name: {kind: 'Name', value: 'organizationId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'description'}},
          {kind: 'Field', name: {kind: 'Name', value: 'dashboardCategoryId'}},
          {kind: 'Field', name: {kind: 'Name', value: 'exploVariables'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageRemoveDashboardModalFragment'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'FragmentSpread', name: {kind: 'Name', value: 'DashboardFormEdit'}},
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'DashboardsPageUpdateDashboardVariablesModal'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Dashboard'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {
            kind: 'FragmentSpread',
            name: {kind: 'Name', value: 'DashboardLinkedToGlobalDashboardFormEdit'},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsPageViewPanelQuery, DashboardsPageViewPanelQueryVariables>;
export const UseCreateDashboardCategoryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useCreateDashboardCategoryMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategoryForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'createDashboardCategory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardCategoryForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardCategory'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseCreateDashboardCategoryMutationMutation,
  UseCreateDashboardCategoryMutationMutationVariables
>;
export const UseDeleteDashboardCategoryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useDeleteDashboardCategoryMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategoryForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'deleteDashboardCategory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardCategoryForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardCategory'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDeleteDashboardCategoryMutationMutation,
  UseDeleteDashboardCategoryMutationMutationVariables
>;
export const UseReorderDashboardCategoriesMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useReorderDashboardCategoriesMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'reorderDashboardCategoriesForm'},
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'ReorderDashboardCategoriesForm'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'reorderDashboardCategories'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reorderDashboardCategoriesForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'reorderDashboardCategoriesForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardCategories'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseReorderDashboardCategoriesMutationMutation,
  UseReorderDashboardCategoriesMutationMutationVariables
>;
export const UseUpdateDashboardCategoryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useUpdateDashboardCategoryMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardCategoryForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateDashboardCategory'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardCategoryForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardCategoryForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardCategory'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateDashboardCategoryMutationMutation,
  UseUpdateDashboardCategoryMutationMutationVariables
>;
export const UseCreateDashboardTagMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useCreateDashboardTagMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardTagForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardTagForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'createDashboardTag'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardTagForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardTagForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardTag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseCreateDashboardTagMutationMutation,
  UseCreateDashboardTagMutationMutationVariables
>;
export const UseDeleteDashboardTagMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useDeleteDashboardTagMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardTagForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardTagForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'deleteDashboardTag'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardTagForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardTagForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardTag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDeleteDashboardTagMutationMutation,
  UseDeleteDashboardTagMutationMutationVariables
>;
export const UseUpdateDashboardTagMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useUpdateDashboardTagMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardTagForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardTagForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateDashboardTag'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardTagForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardTagForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboardTag'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateDashboardTagMutationMutation,
  UseUpdateDashboardTagMutationMutationVariables
>;
export const UseCreateGlobalDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useCreateGlobalDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'createGlobalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'globalDashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'globalDashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseCreateGlobalDashboardMutationMutation,
  UseCreateGlobalDashboardMutationMutationVariables
>;
export const UseDuplicateGlobalDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useDuplicateGlobalDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'duplicateGlobalDashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DuplicateGlobalDashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'duplicateGlobalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'duplicateGlobalDashboardForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'duplicateGlobalDashboardForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'globalDashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDuplicateGlobalDashboardMutationMutation,
  UseDuplicateGlobalDashboardMutationMutationVariables
>;
export const UseUpdateGlobalDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useUpdateGlobalDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'GlobalDashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateGlobalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'globalDashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'globalDashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'errors'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'field'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'message'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'globalDashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateGlobalDashboardMutationMutation,
  UseUpdateGlobalDashboardMutationMutationVariables
>;
export const UseUpdateGlobalDashboardStatusMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useUpdateGlobalDashboardStatusMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'updateGlobalDashboardStatusForm'},
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'UpdateGlobalDashboardStatusForm'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateGlobalDashboardStatus'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'updateGlobalDashboardStatusForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'updateGlobalDashboardStatusForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'globalDashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateGlobalDashboardStatusMutationMutation,
  UseUpdateGlobalDashboardStatusMutationMutationVariables
>;
export const UseCreateDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useCreateDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'createDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseCreateDashboardMutationMutation,
  UseCreateDashboardMutationMutationVariables
>;
export const UseCreateReportDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useCreateReportDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'reportDashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'createReportDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reportDashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'reportDashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseCreateReportDashboardMutationMutation,
  UseCreateReportDashboardMutationMutationVariables
>;
export const UseDeleteReportDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useDeleteReportDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'reportDashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'deleteReportDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reportDashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'reportDashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDeleteReportDashboardMutationMutation,
  UseDeleteReportDashboardMutationMutationVariables
>;
export const UseReorderReportDashboardsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useReorderReportDashboardsMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'reorderReportDashboardsForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'ReorderReportDashboardsForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'reorderReportDashboards'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reorderReportDashboardsForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'reorderReportDashboardsForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboards'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseReorderReportDashboardsMutationMutation,
  UseReorderReportDashboardsMutationMutationVariables
>;
export const UseUpdateDashboardLinkedToGlobalDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useUpdateDashboardLinkedToGlobalDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {kind: 'Name', value: 'dashboardLinkedToGlobalDashboardForm'},
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {kind: 'Name', value: 'DashboardLinkedToGlobalDashboardForm'},
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateDashboardLinkedToGlobalDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardLinkedToGlobalDashboardForm'},
                value: {
                  kind: 'Variable',
                  name: {kind: 'Name', value: 'dashboardLinkedToGlobalDashboardForm'},
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateDashboardLinkedToGlobalDashboardMutationMutation,
  UseUpdateDashboardLinkedToGlobalDashboardMutationMutationVariables
>;
export const UseUpdateDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useUpdateDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'dashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'dashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'uuid'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateDashboardMutationMutation,
  UseUpdateDashboardMutationMutationVariables
>;
export const UseUpdateReportDashboardMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'useUpdateReportDashboardMutation'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'reportDashboardForm'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'DashboardForm'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: {kind: 'Name', value: 'response'},
            name: {kind: 'Name', value: 'updateReportDashboard'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'reportDashboardForm'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'reportDashboardForm'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'dashboard'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseUpdateReportDashboardMutationMutation,
  UseUpdateReportDashboardMutationMutationVariables
>;
