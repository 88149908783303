// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import GlobalAppWrapper from '@supermove/app/src/components/GlobalAppWrapper';
import SupermoveErrorView from '@supermove/app/src/components/SupermoveErrorView';
import {Query} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UserModel} from '@supermove/models';
import {Storage} from '@supermove/sdk';

const updateExperiments = (data: {viewer: UserModel}) => {
  if (data?.viewer?.viewingOrganization?.features?.isEnabledXAPIExperimental) {
    Storage.setItem('X_API_EXPERIMENTAL', 'true');
  } else {
    Storage.setItem('X_API_EXPERIMENTAL', 'false');
  }
};

const SupermoveApp = ({
  showOfflineOverlay,
  skipViewer,
  name,
  version,
  buildNumber,
  onError,
  children,
}: {
  showOfflineOverlay: boolean;
  skipViewer: boolean;
  name: string;
  version: string;
  buildNumber: number;
  onError: (error: Error) => void;
  children: (props: {viewer: UserModel}) => React.ReactNode;
}) => {
  return (
    <GlobalAppWrapper
      showOfflineOverlay={showOfflineOverlay}
      name={name}
      version={version}
      buildNumber={buildNumber}
    >
      <Query
        skip={skipViewer}
        fetchPolicy={'cache-first'}
        query={SupermoveApp.query}
        onCompleted={updateExperiments}
      >
        {/* @ts-expect-error remove when query is properly typed */}
        {({loading, data, error}) => (
          <SupermoveErrorView
            version={version}
            buildNumber={buildNumber}
            onError={onError}
            isAuthenticated={data?.isAuthenticated}
            viewer={data?.viewer}
          >
            {children({viewer: data?.viewer})}
          </SupermoveErrorView>
        )}
      </Query>
    </GlobalAppWrapper>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SupermoveApp.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func,
  showOfflineOverlay: PropTypes.bool,
  skipViewer: PropTypes.bool,
};

SupermoveApp.defaultProps = {
  onError: () => {},
  showOfflineOverlay: true,
  skipViewer: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------

// NOTE(mark): Keeping the name of the query called "Viewer" for backwards compatibility with metrics.
SupermoveApp.query = gql`
  ${SupermoveErrorView.fragment}

  query Viewer {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledXAPIExperimental: isEnabled(feature: "X_API_EXPERIMENTAL")
          isEnabledOfficeZendeskSupportWidget: isEnabled(feature: "OFFICE_ZENDESK_SUPPORT_WIDGET")
          isEnabledOfficeBrainfishSupportWidget: isEnabled(feature: "OFFICE_BRAINFISH_SUPPORT_WIDGET")
        }
      }
      ...SupermoveErrorView
    }
  }
`;

export default SupermoveApp;
