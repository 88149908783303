// Libraries
import {
  ApolloCache,
  BaseMutationOptions,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import {FormikValues} from 'formik';
import {DocumentNode} from 'graphql';

// Supermove
import {useFormErrors, useMutationCallbacks, useTypedMutation} from '@supermove/hooks';

// Modules
import {Form, MutationError} from './types';

type TypedArgs<FormValues extends FormikValues, Results, TData, TVariables> = {
  form: Form<FormValues>;
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>;
  refetchQueries?: BaseMutationOptions['refetchQueries'];
  variables: TVariables;
  onSuccess?: (results: Results) => void;
  onError?: (errors: MutationError[]) => void;
  client?: BaseMutationOptions['client'];
};

const useTypedFormMutation = <
  TData,
  FormValues extends FormikValues,
  Results extends Record<string, any>,
  TVariables extends OperationVariables,
  TContext extends DefaultContext,
  TCache extends ApolloCache<any>,
>({
  form,
  mutation,
  refetchQueries,
  variables,
  onSuccess,
  onError,
  client,
}: TypedArgs<FormValues, Results, TData, TVariables>): {
  submitting: boolean;
  handleSubmit: (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>,
  ) => Promise<FetchResult<TData>>;
} => {
  const [handleSubmit, {loading, data}] = useTypedMutation(mutation, {
    refetchQueries,
    variables,
    client,
  });
  // Trigger validation errors on the form.
  // @ts-expect-error: TODO (zharkyn) Fix this
  useFormErrors({form, data});
  // Trigger callbacks when the data field changes.
  // @ts-expect-error: TODO (zharkyn) Fix this
  useMutationCallbacks({data, onSuccess, onError});

  return {
    submitting: loading,
    // @ts-expect-error: TODO (zharkyn) Fix this
    handleSubmit,
  };
};

export default useTypedFormMutation;
