// Libraries
import React from 'react';

export interface UnitInputParserProps extends Record<string, unknown> {
  component?: any;
  value: string;
  onChangeText: (value: string) => void;
  onBlur?: (event: any) => void;
  options?: Record<string, unknown>;
  unitString?: string;
}

class UnitInputParser extends React.Component<UnitInputParserProps> {
  getMatches = (string: string, regex: RegExp) => {
    return string.match(regex) || [];
  };

  formatValue = (value: string) => {
    if (value === '') {
      return '';
    }

    // Allows for numbers with an optional decimal.
    const numerics = this.getMatches(value, /\d+[.\d+]?/g).join('');
    const alpha = this.getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const float = parseFloat(numerics) || 0;

    return `${float} ${this.props.unitString}`;
  };

  handleBlur = () => {
    this.props.onChangeText(this.formatValue(this.props.value));
  };

  render() {
    const {
      component: InputComponent = 'input',
      value,
      onChangeText,
      onBlur,
      options = {},
      ...props
    } = this.props;

    return (
      <InputComponent
        {...props}
        value={value}
        onChangeText={onChangeText}
        onBlur={(event: any) => {
          onBlur && onBlur(event);
          this.handleBlur();
        }}
      />
    );
  }
}

export default UnitInputParser;
