module.exports = [{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-react-x/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","map","css"]},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-amplitude/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"b75a89d46707e285ea2ef0ee12b7040e"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140401595-5"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"e25894e93707dd4eedffd41291b57d30"},
    },{
      plugin: require('/home/runner/work/supermove/supermove/apps/customer/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
